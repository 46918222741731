import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
    TextField,
    Button,
    MenuItem,
    Grid,
    Box,
    Typography,
    FormControl,
    Select,
    InputLabel,
    FormHelperText
} from '@mui/material';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth_API from '../../../utilities/api/auth_api';
import TinkerLabListAPIs from '../../../utilities/api/TinkerLabListAPIs';

const typographyStyle = {
    color: "var(--Primary-Primary03, #5C5C5C)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "1.2rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.0165rem",
};

const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: ".6rem",
    alignSelf: "stretch",
    marginBottom: "1rem",
};

const styledTextfield = {
    display: "flex",
    gap: "0.2rem",
    alignSelf: "stretch",
    border: "1px solid #B5B5B5",
    borderRadius: "0.75rem",
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
    },
    '& .MuiInputBase-root': {
        borderRadius: '12px',
        '& input': {
            padding: '0.6rem 1rem',
        }
    }
};

const degree_options = ['B.Tech', 'M.Tech', 'M.Sc', 'PhD', 'MCA', 'MBA', 'Other'];
const department_options = [
    ['CSE', 'Computer Science and Engineering'],
    ['ECE', 'Electronics and Communication Engineering'],
    ['ME', 'Mechanical Engineering'],
    ['CE', 'Civil Engineering'],
    ['EE', 'Electrical Engineering'],
    ['CHE', 'Chemical Engineering'],
    ['MME', 'Metallurgical and Materials Engineering'],
    ['BT', 'Biotechnology'],
    ['CL', 'Chemistry'],
    ['MA', 'Mathematics'],
    ['PH', 'Physics'],
    ['HSS', 'Humanities and Social Sciences'],
    ['MC', 'Mathematics and Computing'],
    ['MSE', 'Materials Engineering'],   
    ['Other', 'Other']
];

const yearOfStudyOptions = [
    { key: 1, label: 'First' },
    { key: 2, label: 'Second' },
    { key: 3, label: 'Third' },
    { key: 4, label: 'Fourth' },
    { key: 5, label: 'Fifth' }
];

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone_number: Yup.string().required('Phone number is required'),
    graduation_year: Yup.string().required('Graduation Year is required'),
    hostel_address: Yup.string(),
    department: Yup.string().required('Department is required'),
    degree: Yup.string().required('Degree is required'),
    current_year: Yup.string().required('Current Year is required'),
    TL: Yup.string().required("Tinkerers' Lab is required"),
});

const StudentRegistrationForm = () => {
    const navigate = useNavigate();
    const [iitDomains, setIitDomains] = useState({});
    const [allTLs, setAllTLs] = useState([]);
    const [TLs, setTLs] = useState([]);
    const [isTLDisabled, setIsTLDisabled] = useState(false);

    useEffect(() => {
        TinkerLabListAPIs.TinkerLabsListGet().then((data) => {
            const domains = {};
            const allTinkerLabs = [];

            data.forEach(tl => {
                allTinkerLabs.push(tl.name);
                tl.accepted_ldaps.forEach(domain => {
                    if (!domains[domain.replace('@', '')]) {
                        domains[domain.replace('@', '')] = [];
                    }
                    domains[domain.replace('@', '')].push(tl.name);
                });
            });

            setIitDomains(domains);
            setAllTLs(allTinkerLabs);
        }).catch((error) => {
            console.error('Error fetching TL data:', error);
        });
    }, []);

    const checkIITEmail = (email) => {
        const domain = email.split('@')[1];
        return iitDomains[domain] || [];
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone_number: '',
            graduation_year: '',
            hostel: '',
            department: '',
            degree: '',
            current_year: '',
            TL: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            Auth_API.studentRegister(values).then((result) => {
                console.log(result);
                toast.success('You have registered successfully! You will receive login credentials via your registered email id.');
                navigate('/login');
            }).catch((error) => {
                console.log(error);
                toast.error(error.response.error || error.response.data.error || 'An unexpected error occurred. Please try again.');
            });
        }
    });

    useEffect(() => {
        const colleges = checkIITEmail(formik.values.email);
        if (colleges.length === 0) {
            formik.setFieldValue('TL', '');
        } else {
            formik.setFieldValue('TL', colleges[0] || "");
        }

        if (colleges.length > 1) {
            setIsTLDisabled(false);
        } else if (colleges.length === 1) {
            setIsTLDisabled(true);
        } else {
            setIsTLDisabled(false);
        }

        setTLs(colleges);
    }, [formik.values.email, iitDomains]);

    return (
        <Box sx={{ width:'100%', backgroundColor: 'transparent', padding: { xs: '1rem', md: '2rem' } }}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Name
                            </Typography>
                            <TextField
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                sx={styledTextfield}
                                placeholder="Enter your name"
                                fullWidth
                            />
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Email Address
                            </Typography>
                            <TextField
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                sx={styledTextfield}
                                placeholder="Enter email address"
                                fullWidth
                            />
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Phone Number
                            </Typography>
                            <TextField
                                id="phone_number"
                                name="phone_number"
                                value={formik.values.phone_number}
                                onChange={formik.handleChange}
                                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                helperText={formik.touched.phone_number && formik.errors.phone_number}
                                sx={styledTextfield}
                                placeholder="Enter your phone number"
                                fullWidth
                            />
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Graduation Year
                            </Typography>
                            <TextField
                                id="graduation_year"
                                name="graduation_year"
                                value={formik.values.graduation_year}
                                onChange={formik.handleChange}
                                error={formik.touched.graduation_year && Boolean(formik.errors.graduation_year)}
                                helperText={formik.touched.graduation_year && formik.errors.graduation_year}
                                sx={styledTextfield}
                                placeholder="Enter your expected graduation year"
                                fullWidth
                            />
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Hostel
                            </Typography>
                            <TextField
                                id="hostel_address"
                                name="hostel_address"
                                value={formik.values.hostel_address}
                                onChange={formik.handleChange}
                                error={formik.touched.hostel_address && Boolean(formik.errors.hostel_address)}
                                helperText={formik.touched.hostel_address && formik.errors.hostel_address}
                                sx={styledTextfield}
                                placeholder="Enter your current hostel"
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                TL Name
                            </Typography>
                            <FormControl sx={styledTextfield} fullWidth error={formik.touched.TL && Boolean(formik.errors.TL)}>
                                <InputLabel id="TL-label">Select your TL</InputLabel>
                                <Select
                                    labelId="TL-label"
                                    id="TL"
                                    name="TL"
                                    value={formik.values.TL}
                                    onChange={formik.handleChange}
                                    label="Select your TL"
                                    disabled={isTLDisabled}
                                >
                                    {TLs.length > 0 ? (
                                        TLs.map((tl) => (
                                            <MenuItem key={tl} value={tl}>
                                                {tl}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                    )}
                                </Select>
                                {formik.touched.TL && formik.errors.TL && (
                                    <FormHelperText>{formik.errors.TL}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Department
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    labelId="department-label"
                                    id="department"
                                    name="department"
                                    value={formik.values.department}
                                    label="department"
                                    onChange={formik.handleChange}
                                    error={formik.touched.department && Boolean(formik.errors.department)}
                                >
                                    {department_options.map((option, index) => (
                                        <MenuItem key={index} value={option[0]}>
                                            {option[1]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Degree
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    labelId="degree-label"
                                    id="degree"
                                    name="degree"
                                    value={formik.values.degree}
                                    label="degree"
                                    onChange={formik.handleChange}
                                    error={formik.touched.degree && Boolean(formik.errors.degree)}
                                >
                                    {degree_options.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Year of Study
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    labelId="current-year-label"
                                    id="current_year"
                                    name="current_year"
                                    value={formik.values.current_year}
                                    label="Year of Study"
                                    onChange={formik.handleChange}
                                    error={formik.touched.current_year && Boolean(formik.errors.current_year)}
                                >
                                    {yearOfStudyOptions.map((option) => (
                                        <MenuItem key={option.key} value={option.key}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.current_year && formik.errors.current_year && (
                                    <FormHelperText>{formik.errors.current_year}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    gap: '2rem',
                    marginTop: '10px',
                }}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            formik.resetForm();
                            navigate("/");
                        }}
                        sx={{
                            width: { xs: '100%', md: '12rem' },
                            padding: '0.8rem 1rem',
                            borderRadius: '0.75rem',
                            background: '1px solid var(--Primary-Primary03, #5C5C5C)',
                        }}
                    >
                        <Typography sx={{ color: 'black' }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            width: { xs: '100%', md: '12rem' },
                            padding: '0.8rem 1rem',
                            borderRadius: '0.75rem',
                            background: 'var(--Accent-Accent-00, #B2D7FF)',
                        }}>
                        <Typography sx={{
                            color: 'black',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '1.2rem',
                            fontStyle: 'normal',
                            fontWeight: 400,
                        }}>
                            Register
                        </Typography>
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default StudentRegistrationForm;
