import React, { useState } from "react";
import {
    Box,
    Typography,
    Container,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import BackgroundImage from "../../assets/Background_Image.png"; // New background image
import MBFLogo from "../../utilities/dummy_assets/MBF_Logo.png"; // New logo
import MapitLogo from "../../utilities/dummy_assets/mapitLoginLogo.png"; // Footer logo
import { useNavigate } from "react-router-dom";
import StudentRegistrationForm from "../../components/auth/register/StudentRegistrationForm";

const typographyStyle = {
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "-0.01375rem",
};

const RegisterPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box sx={{ display: "flex", position: "relative", minHeight: "100vh", flexDirection: "column" }}>
            {/* Logo at top-left corner */}
            <Box
                sx={{
                    position: "absolute",
                    top: "24px",
                    left: "24px",
                }}
            >
                <img src={MBFLogo} alt="MBF Logo" style={{ width: "100px", height: "auto" }} />
            </Box>

            {/* Main Content */}
            <Box
                sx={{
                    width: "100%",
                    flex: 1,
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: {
                        xs: "cover",
                        sm: "contain",
                        md: "cover",
                    },
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: {
                        xs: "center",
                        sm: "top",
                        md: "center 220px",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Container
                    sx={{
                        width:'60%',
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // gap: "1.5rem",
                        padding: "1rem",
                        borderRadius: "1rem",
                        backgroundColor: "#FFF",
                        // width: { xs: "90%", sm: "80%", md: "40%", lg: "25.2rem" },
                        boxShadow: "4px 4px 44px 0px #0000001A",
                        // marginTop: { xs: "5rem", md: "8rem" },
                    }}
                >
                    <StudentRegistrationForm />
                </Container>
            </Box>

            {/* Footer Logo */}
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    padding: "1rem 0",
                }}
            >
                <Typography
                    sx={{
                        color: "white",
                        fontFamily: 'roboto',
                        fontSize: '24px',
                    }}
                >
                    Powered by
                    <img src={MapitLogo} alt="Mapit Logo" style={{ marginLeft: "0.5rem", width: "100px", height: "auto", verticalAlign: "middle" }} />
                </Typography>
            </Box>
        </Box>
    );
};

export default RegisterPage;
