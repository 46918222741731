import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import WorkshopContent from "./Workshop/WorkshopContent";
import WorkshopCards from "./Workshop/WorkshopCards";
import TutorialContent from "./Tutorial/TutorialContent";
import CoursePage from "./Tutorial/CoursePage";
import VideoCardView from "./Tutorial/VideoCardView";
import { onTutorialPage } from "../../redux/slices/tutorialSlice";
import StudentWorkshopList from "utilities/api/workshop/StudentWorkshopList";
import ListCardView from "./Workshop/ListCardView";
import { useLocation } from 'react-router-dom';
import nodatafoundImage from '../../utilities/dummy_assets/nodatafound.jpg'; // Import the No Data Found image

const TopbarTraining = () => {
  const { user } = useSelector((state) => state.user);
  const [value, setValue] = useState(0);
  const location = useLocation();

  const dispatch = useDispatch();
  const onDetail = useSelector((state) => state.tutorial.onDetail); // Tutorial
  const tutorialID = useSelector((state) => state.tutorial.detailId); // Tutorial
  const isDetailedWorkshop = useSelector(state => state.workshop.isDetailedWorkshop); // Workshop
  const filteredWorkshopData = useSelector(state => state.workshop.workshopData);
  const onTutorialPageValue = useSelector((state) => state.tutorial.onTutorialPage);
  const workshopCategory = useSelector(state => state.workshop.workshopCategory);

  console.log("onDetail", onDetail);
  console.log("detailId", tutorialID);
  console.log("onTutorialPage", onTutorialPageValue);

  // TO HANDLE OPENING OF RESPECTIVE TAB UPON REDIRECT FROM DASHBOARD
  useEffect(() => {
    if (location.state && location.state.tab !== undefined) {
      setValue(location.state.tab);
    }
  }, [location.state]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [workshopData, setWorkshopData] = useState([]);

  useEffect(() => {
    // Function to fetch workshop data from API
    const fetchWorkshopData = async () => {
      try {
        const data = await StudentWorkshopList.WorkshopListGet();
        setWorkshopData(data);
      } catch (error) {
        console.error("Error fetching workshop data:", error);
      }
    };

    fetchWorkshopData();
  }, [user]);

  return (
    <div style={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="#fff"
        indicatorColor="primary"
        sx={{ padding: "16px 16px", justifyContent: "flex-start" }}
      >
        <Tab label="Tutorial" sx={tabStyles(value === 0)} />
        <Tab label="Workshop" sx={tabStyles(value === 1)} />
        {/* <Tab label="My Learning" sx={tabStyles(value === 2)} /> */}
      </Tabs>

      <TabPanel value={value} index={0}>
        {onTutorialPageValue ? (
          <VideoCardView tutorialID={tutorialID} />
        ) : onDetail ? (
          <CoursePage tutorialID={tutorialID} />
        ) : (
          <TutorialContent />
        )}
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {workshopData.length > 0 ? (
            <div>
              {isDetailedWorkshop ? (
                <ListCardView workshopData={filteredWorkshopData} workshopCategory={workshopCategory} />
              ) : (
                <WorkshopCards workshopData={workshopData} />
              )}
            </div>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} padding="40px">
              <img src={nodatafoundImage} alt="No Data Found" style={{ width: '300px', marginBottom: '16px' }} />
              <Typography variant="h6" color="textSecondary">
                No data available
              </Typography>
            </Box>
          )}
        </Box>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          Content for My Learning
        </Box>
      </TabPanel>
    </div>
  );
};

const tabStyles = (isSelected) => ({
  display: 'flex',
  padding: '12px 40px',
  alignItem: 'flex-start',
  gap: '24px',
  fontWeight:'500',
  fontSize:'1.2rem',
  color: isSelected ? "#273F77" : "#979797",
});

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tabpanel-${index}`}
    {...other}
    style={{ width: "100%" }}
  >
    {value === index && (
      <Box sx={{ padding: "0px 24px" }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

export default TopbarTraining;
