import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@mui/material';

const TableListHeader = () => {
  const [category, setCategory] = useState('');

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <Table sx={{ width: '100%' }}>
      <TableHead>
        <TableRow
          sx={{
            bgcolor: '#F7F7F7',
            borderBottom: '1px solid #F2F2F2',
          }}
        >
          <TableCell
            sx={{
              textAlign: 'center',
              width: '19%',
              padding: '0.875rem 0.75rem',
              borderBottom: '1px solid #F2F2F2',
            }}
          >
            <TableSortLabel
              hideSortIcon
              sx={{
                color: '#5C5C5C',
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '20px',
              }}
            >
              Item Details
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'center',
              width: '25%',
              padding: '0.875rem 0.75rem',
              borderBottom: '1px solid #F2F2F2',
            }}
          >
            <TableSortLabel
              hideSortIcon
              sx={{
                color: '#5C5C5C',
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '20px',
              }}
            >
              Category
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'center',
              width: '12%',
              padding: '0.875rem 0.75rem',
              borderBottom: '1px solid #F2F2F2',
            }}
          >
            <TableSortLabel
              hideSortIcon
              sx={{
                color: '#5C5C5C',
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '20px',
              }}
            >
              Stock
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'center',
              width: '20%',
              padding: '0.875rem 0.75rem',
              borderBottom: '1px solid #F2F2F2',
            }}
          >
            <TableSortLabel
              hideSortIcon
              sx={{
                color: '#5C5C5C',
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '20px',
              }}
            >
              Tags
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'center',
              width: '20%',
              padding: '0.875rem 0.75rem',
              borderBottom: '1px solid #F2F2F2',
            }}
          >
            <TableSortLabel
              hideSortIcon
              sx={{
                color: '#5C5C5C',
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '20px',
              }}
            >
              Action
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

export default TableListHeader;
