import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardMedia, CardContent, Typography, Box, Button, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { setOnDetail, onTutorialPage } from '../../../redux/slices/tutorialSlice';
import ImageBaseURL from 'utilities/api/axios';

// const baseURL = 'https://staging.misadmin.mapit.ai/';

const GridTutorialCard = ({ item }) => {
  const dispatch = useDispatch();

  const handleInfoClick = (id) => {
    console.log('handleInfoClick', id);
    dispatch(setOnDetail({ onDetail: true, id }));
  };

  const handleWatchNowClick = (id) => {
    dispatch(setOnDetail({ onDetail: true, id }));
    dispatch(onTutorialPage(true));
  };

  const getDescriptionPreview = (description) => {
    const words = description.split(' ');
    if (words.length <= 10) {
      return description;
    }
    return words.slice(0, 10).join(' ') + '...';
  };

  return (
    <Card
      sx={{
        position: 'relative',
        border:'1px solid #B5B5B5',
        borderRadius: '30px',
        overflow: 'hidden',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth:'400px',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          padding: '15px',
          paddingBottom:'0px',
        }}
      >
        <CardMedia
          component="img"
          image={item.image ? `${ImageBaseURL.defaults.baseURL}${item.image}` : 'default-image-path'} // Add a default image path if item.image is null
          alt={item.name}
          sx={{
            width: '100%',
            height: '250px',
            borderRadius: '15px',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '10px',
            left: '22px',
            padding: '2px 8px',
            backgroundColor: 'white',
            border: '1px solid #2196f3',
            borderRadius: '12px',
            color: '#2196f3',
            fontSize: '12px',
          }}
        >
          <Typography variant="body2">{item.tag || 'N/A'}</Typography>
        </Box>
        <IconButton
          sx={{
            position: 'absolute',
            color:'#273F77',
            top: '20px',
            right: '20px',
            padding:'2px',
            backgroundColor: 'white',
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 1)',
            },
          }}
          onClick={() => handleInfoClick(item.id)}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </Box>
      <CardContent>
        <Typography variant="h6" component="div">
          {item.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.videos.length} Videos
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ marginTop: '8px' }}>
          {getDescriptionPreview(item.description)}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '8px',
          }}
        >
          <Button
            variant="contained"
            startIcon={<PlayArrowIcon />}
            onClick={() => handleWatchNowClick(item.id)}
            sx={{
              flexGrow: 1,
              margin: '0 4px',
              fontSize: '12px',
              textTransform: 'none',
              backgroundColor: '#E79A2B',
              color: '#FFF',
              '&:hover': {
                backgroundColor: '#f9c346',
              },
            }}
          >
            Watch Now
          </Button>
          <Button
            variant="outlined"
            startIcon={<PlaylistAddIcon />}
            sx={{
              flexGrow: 1,
              margin: '0 4px',
              fontSize: '12px',
              textTransform: 'none',
              borderColor: '#000',
              color: '#000',
              '&:hover': {
                borderColor: '#000',
              },
            }}
          >
            Add to watchlist
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GridTutorialCard;

// import React from 'react';
// import { useDispatch } from 'react-redux';
// import { Card, CardMedia, CardContent, Typography, Box, Button, IconButton } from '@mui/material';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { setOnDetail, onTutorialPage } from '../../../redux/slices/tutorialSlice';

// const GridTutorialCard = ({ item }) => {
//   const dispatch = useDispatch();

//   const handleInfoClick = (id) => {
//     console.log('handleInfoClick', id);
//     dispatch(setOnDetail({ onDetail: true, id }));
//   };

//   const handleWatchNowClick = (id) => {
//     dispatch(setOnDetail({ onDetail: true, id }));
//     dispatch(onTutorialPage(true));
//   };

//   return (
//     <Card
//       sx={{
//         position: 'relative',
//         border:'1px solid #B5B5B5',
//         borderRadius: '30px',
//         overflow: 'hidden',
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//         maxWidth:'400px',
//       }}
//     >
//       <Box
//         sx={{
//           position: 'relative',
//           padding: '15px',
//           paddingBottom:'0px',
//         }}
//       >
//         <CardMedia
//           component="img"
//           image={item.image}
//           alt={item.topic}
//           sx={{
//             width: '100%',
//             height: '250px',
//             borderRadius: '15px',
//           }}
//         />
//         <Box
//           sx={{
//             position: 'absolute',
//             bottom: '10px',
//             left: '22px',
//             padding: '2px 8px',
//             backgroundColor: 'white',
//             border: '1px solid #2196f3',
//             borderRadius: '12px',
//             color: '#2196f3',
//             fontSize: '12px',
//           }}
//         >
//           <Typography variant="body2">{item.tag}</Typography>
//         </Box>
//         <IconButton
//           sx={{
//             position: 'absolute',
//             color:'#273F77',
//             top: '20px',
//             right: '20px',
//             padding:'2px',
//             backgroundColor: 'white',
//             borderRadius: '50%',
//             '&:hover': {
//               backgroundColor: 'rgba(255, 255, 255, 1)',
//             },
//           }}
//           onClick={() => handleInfoClick(item.id)}
//         >
//           <InfoOutlinedIcon />
//         </IconButton>
//       </Box>
//       <CardContent>
//         <Typography variant="h6" component="div">
//           {item.topic}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {item.no_of_videos} Videos ・ {item.no_of_time}
//         </Typography>
//         <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             marginTop: '8px',
//           }}
//         >
//           <Button
//             variant="contained"
//             startIcon={<PlayArrowIcon />}
//             onClick={() => handleWatchNowClick(item.id)}
//             sx={{
//               flexGrow: 1,
//               margin: '0 4px',
//               fontSize: '12px',
//               textTransform: 'none',
//               backgroundColor: '#E79A2B',
//               color: '#FFF',
//               '&:hover': {
//                 backgroundColor: '#f9c346',
//               },
//             }}
//           >
//             Watch Now
//           </Button>
//           <Button
//             variant="outlined"
//             startIcon={<PlaylistAddIcon />}
//             sx={{
//               flexGrow: 1,
//               margin: '0 4px',
//               fontSize: '12px',
//               textTransform: 'none',
//               borderColor: '#000',
//               color: '#000',
//               '&:hover': {
//                 borderColor: '#000',
//               },
//             }}
//           >
//             Add to watchlist
//           </Button>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

// export default GridTutorialCard;
