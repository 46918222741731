import React, { useState } from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, CircularProgress } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Box } from "@mui/material";
import EquipmentDetails from 'components/popups/EquipmentDetails';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector } from 'react-redux';
import ImageBaseURL from 'utilities/api/axios';

const EquipmentCardView = ({ item, searchQuery }) => {
    const { access, user } = useSelector((state) => state.user);
    console.log('EquipmentCardView-Item', item);

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const filterData = (details) => {
        const query = searchQuery.toLowerCase();
        const { name, category, location, availability } = details;

        return (
            (name && name.toLowerCase().includes(query)) ||
            (category && category.toLowerCase().includes(query)) ||
            (location && location.toLowerCase().includes(query)) ||
            (availability && availability.toLowerCase().includes(query))
        );
    };

    const filtered = filterData(item);

    // Styles
    const cardStyles = {
        maxWidth: 250,
        padding: '1rem .5rem',
        borderRadius: '1rem',
        border: '2px solid var(--Primary-Primary01, rgba(160, 160, 160, 0.3) )',
        background: 'var(--Primary-Primary-11, #e8e8f2e6)',
        margin: '10px 0px 0px 10px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Increased shadow
        transition: 'box-shadow 0.3s ease', // Transition for smooth effect
        '&:hover': {
            border: '2px solid var(--Primary-Primary01, rgba(255, 134, 0, 0.35) )',
        },
    };

    const mediaStyles = {
        height: 150,
        borderRadius: '.75rem',
    };

    const titleStyles = {
        color: '#686868',
        fontFamily: 'Inter',
        fontSize: '30px',
        fontWeight: '400',
        lineHeight: '1.5',
        marginBottom: '5px',
    };

    const infoStyles = {
        color: '#717070',
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: '350',
        lineHeight: '1.5',
    };

    const infoMarker = {
        fontWeight: '500',
        color: '#666565',
    };

    return (
        <>
            {filtered && (
                <Card sx={cardStyles}>
                    <CardMedia
                        sx={mediaStyles}
                        image={item.image ? `${ImageBaseURL.defaults.baseURL}${item.image}` : "./machine.png"} // Conditional image rendering
                        title={item.name}
                    />
                    <CardContent sx={{ padding: '10px 0px 0px 10px' }}>
                        <Typography variant="h5" sx={titleStyles}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {item.name}
                                <Button onClick={handleOpenDialog} sx={{ marginLeft: 'auto' }}>
                                    <VisibilityIcon sx={{ color: '#273f77' }} />
                                </Button>
                                <EquipmentDetails
                                    open={openDialog}
                                    onClose={handleCloseDialog}
                                    machineId={item.id}
                                    machineDetails={item}
                                />
                            </Box>
                        </Typography>
                        <Typography variant="body1" sx={infoStyles}>
                            <span style={infoMarker}>Category:</span> {item.category || 'N/A'}
                        </Typography>
                        {/* <Typography variant="body1" sx={infoStyles}>
                            <span style={infoMarker}>Availability:</span> {item.availability || 'N/A'}
                        </Typography> */}
                        <Typography variant="body1" sx={infoStyles}>
                            <span style={infoMarker}>Location:</span> {item.location || 'N/A'}
                        </Typography>
                        <Typography variant="body1" sx={infoStyles}>
                            <span style={infoMarker}>Description:</span> {item.description || 'N/A'}
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default EquipmentCardView;
