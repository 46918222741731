import React, { useState, useEffect, useCallback } from 'react';
import { TableContainer, Paper, Box, TextField, InputAdornment, Typography, Button, CircularProgress, Table, TableBody } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomPagination from '../Pagination'; // Use the CustomPagination you provided
import WorkbenchBookingHeader from './WorkbenchBookingHeader';
import WorkbenchBookingListItem from './WorkbenchBookingListItem';
import BookWorkBench from 'components/ExploreTL/workbench/BookWorkBench';
import nodatafoundImage from '../../utilities/dummy_assets/nodatafound.jpg';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import WorkbenchBookingAPIs from 'utilities/api/StudentWorkbenchBookingAPIs';

const WorkbenchBookingsTab = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [bookings, setBookings] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openBookDialog, setOpenBookDialog] = useState(false);
    const [productsPerPage, setProductsPerPage] = useState(4); // Add dynamic productsPerPage state

    // Fetch workbench bookings from API
    const fetchBookings = async () => {
        try {
            setLoading(true);
            const data = await WorkbenchBookingAPIs.getList(); // Fetch data from API
            setBookings(data); // Set the fetched data to state
            setFilteredData(data); // Initially set filtered data to all bookings
        } catch (error) {
            setError('Failed to fetch bookings. Please try again later.');
        } finally {
            setLoading(false); // Stop loading whether successful or failed
        }
    };

    // Filter data based on the search query
    const filterData = useCallback(() => {
        setFilteredData(
            bookings.filter((item) =>
                (item.project?.title?.toLowerCase() || 'no project').includes(searchQuery.toLowerCase())
            )
        );        
    }, [searchQuery, bookings]);

    useEffect(() => {
        fetchBookings(); // Fetch bookings on component mount
    }, []);

    useEffect(() => {
        filterData(); // Filter data whenever search query or bookings change
        setCurrentPage(1); // Reset to first page when filtering
    }, [searchQuery, filterData]);

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / productsPerPage);

    console.log(`Total Items: ${totalItems}, Total Pages: ${totalPages}, Products Per Page: ${productsPerPage}`); // Debugging log

    const currentProducts = filteredData.slice(
        (currentPage - 1) * productsPerPage,
        currentPage * productsPerPage
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePageChange = (event, value) => {
        if (value > totalPages) {
            setCurrentPage(totalPages); // Avoid setting a page higher than total pages
        } else {
            setCurrentPage(value);
        }
    };

    const handleDelete = async (id) => {
        try {
            await WorkbenchBookingAPIs.deleteBooking(id); // Delete booking via API
            setBookings((prevBookings) => prevBookings.filter((booking) => booking.id !== id));
            // After deleting, re-filter the data
            setFilteredData((prevFiltered) => prevFiltered.filter((booking) => booking.id !== id));
        } catch (error) {
            console.error('Failed to delete the booking:', error);
        }
    };

    const handleOpenDialog = () => {
        setOpenBookDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenBookDialog(false);
        fetchBookings(); // Refresh bookings after closing dialog
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <Typography variant="h6" color="error">{error}</Typography>
                    </Box>
                ) : filteredData.length > 0 ? (
                    <>
                        <Box
                            className="headerContainer"
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '16px',
                            }}
                        >
                            <TextField
                                label="Search Projects"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ m: 1, minWidth: 250 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpenDialog}
                                sx={{
                                    backgroundColor: "#E79A2B",
                                    color: "white",
                                    borderRadius: "8px",
                                    padding: "8px 16px",
                                    textTransform: "none",
                                    border: "2px solid #212121",
                                }}
                            >
                                Book Work Bench
                            </Button>
                        </Box>

                        <TableContainer
                            component={Paper}
                            elevation={0}
                            sx={{
                                overflowY: 'auto',
                                maxHeight: '590px', // Adjust as needed
                                border: '1px solid black',
                                borderRadius: '20px',
                            }}
                        >
                            <Table stickyHeader>
                                <WorkbenchBookingHeader />
                                <TableBody>
                                    {currentProducts.map((item) => (
                                        <WorkbenchBookingListItem
                                            key={item.id}
                                            product={item}
                                            onDelete={handleDelete}
                                            onUpdate={fetchBookings} // Pass the onUpdate prop here
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                            {totalPages > 0 && (
                                <CustomPagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    productsPerPage={productsPerPage}
                                    setProductsPerPage={setProductsPerPage} // Passing props for products per page selection
                                />
                            )}
                        </TableContainer>
                    </>
                ) : (
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} padding="40px">
                        <img src={nodatafoundImage} alt="No Data Found" style={{ width: '300px', marginBottom: '16px' }} />
                        <Typography variant="h6" color="textSecondary">
                            No data available
                        </Typography>
                    </Box>
                )}

                {/* Book WorkBench Dialog */}
                {openBookDialog && (
                    <BookWorkBench open={openBookDialog} onClose={handleCloseDialog} />
                )}
            </Box>
        </LocalizationProvider>
    );
};

export default WorkbenchBookingsTab;
