import React from 'react';
import { Box, Grid, Typography, Switch } from '@mui/material';

const AlertSettings = () => {
  return (
    <Box sx={{ padding: 8, border: '1px solid #B5B5B5', borderRadius: '12px', margin: 'auto' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Alerts:</Typography>
        </Grid>
        <Grid item xs={6} >
          <Typography sx={{ml: 4}}>Email</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch defaultChecked sx={{ml: 4}} />
        </Grid>
        <Grid item xs={6} >
          <Typography sx={{ml: 4}}>Desktop</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch sx={{ml: 4}} />
        </Grid>
        <Grid item xs={6} >
          <Typography sx={{ml: 4}}>Mobile</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch defaultChecked sx={{ml: 4}}/>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Allow Alerts for:</Typography>
        </Grid>
        <Grid item xs={6} >
          <Typography sx={{ml: 4}}>Booking Confirmation</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch defaultChecked sx={{ml: 4}} />
        </Grid>
        <Grid item xs={6} >
          <Typography sx={{ml: 4}}>Equipment Availability</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch sx={{ml: 4}}/>
        </Grid>
        <Grid item xs={6} >
          <Typography sx={{ml: 4}}>Workshop Reminders</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch defaultChecked sx={{ml: 4}}/>
        </Grid>
        <Grid item xs={6} >
          <Typography sx={{ml: 4}}>Upcoming events</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch defaultChecked sx={{ml: 4}}/>
        </Grid>
        <Grid item xs={6} >
          <Typography sx={{ml: 4}}>In-app messages</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch defaultChecked sx={{ml: 4}}/>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" >Sound</Typography>
        </Grid>
        {/* <Grid item xs={6}></Grid> */}
        <Grid item xs={6}>
          <Switch defaultChecked sx={{ml: 8}}/>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6" >Banners</Typography>
        </Grid>
        {/* <Grid item xs={6}></Grid> */}
        <Grid item xs={6}>
          <Switch sx={{ml: 8}}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlertSettings;
