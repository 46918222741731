import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
import { Signup } from "./pages/Signup.jsx"; // Adjust the import path as necessary
import { Login } from "./pages/Login.jsx"; // Adjust the import path as necessary
import Dashboard from "./pages/dashboard.jsx"; // Adjust the import path as necessary
import ExploreTL from "./pages/exploretl.jsx"; // Adjust the import path as necessary
import Projects from "./pages/projects.jsx"; // Adjust the import path as necessary
import Records from "./pages/records.jsx"; // Adjust the import path as necessary
import Profile from "./pages/Profile.jsx"; // Adjust the import path as necessary
import { useUser } from "./UserContext"; // Adjust the import path as necessary
import RegisterPage from "pages/auth/Register.jsx";
import CommonLoginPage from "pages/auth/commonLogin.jsx";
import AdminLoginPage from "pages/auth/adminLogin.jsx";
import StudentLoginPage from "pages/auth/studentLogin.jsx";
import ProtectedRoute from "components/utils/ProtectedRoute.jsx";
import Training from "pages/training.jsx";
import { useAxiosInterceptors } from "utilities/api/axios.js";
import ReportIssue from "components/ReportIssue/ReportIssue.jsx";
import RoutesWrapper from "routes.js";
import Calendar from "components/calendar/calendar.js";
import { ToastContainer } from "react-toastify";

export const App = () => {
    const [isInterceptorReady, setIsInterceptorReady] = useState(false);

    // Custom hook to set up Axios interceptors
    useAxiosInterceptors(setIsInterceptorReady);

    // If interceptors are not set up, show a loading state or return null
    if (!isInterceptorReady) {
        return <div>Loading...</div>; // You can customize this loading state
    }

    // Render the application once interceptors are set up
    return (
        <Router>
            <ReportIssue />
            <RoutesWrapper />
            <ToastContainer />
        </Router>
    );
};
