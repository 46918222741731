// PublicRoute.js
import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import StudentUserDetailAPIs from "utilities/api/StudentUserDetailAPIs";
import { updateAccessToken, logoutUser } from "../../redux/slices/userSlice";
import axiosAuthService from "../../utilities/api/axios";

const PublicRoute = ({ children }) => {
    const { access, refresh, user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        const validateToken = async () => {
            if (access) {
                try {
                    // Attempt to validate token by calling StudentDetailGet
                    const response = await StudentUserDetailAPIs.StudentDetailGet(user.id);
                    console.log('Response:', response);
                    if (response) {
                        // Token is valid; redirect to dashboard
                        navigate("/dashboard");
                    }
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        // Unauthorized response, try refreshing the token
                        try {
                            const refreshResponse = await axiosAuthService.post("refresh/", { refresh });
                            dispatch(updateAccessToken(refreshResponse.data.access));
                            // Retry validation with new access token
                            const retryResponse = await StudentUserDetailAPIs.StudentDetailGet(user.id);
                            if (retryResponse) {
                                navigate("/dashboard");
                            }
                        } catch (refreshError) {
                            // If token refresh fails, log out the user
                            dispatch(logoutUser());
                            navigate("/login");
                        }
                    }
                }
            }
        };
        
        validateToken();
    }, [access, refresh, dispatch, navigate]);

    // Render children if no redirection occurs
    return <>{children}</>;
};

export default PublicRoute;
