import React from 'react';
import { Box, Typography, Paper, Stack } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const ListVideoContainer = ({ videos }) => {
  return (
    <Stack spacing={2}>
      {videos.map((video, index) => (
        <Paper
          key={video.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '16px',
            backgroundColor: '#f2f3fc',
            border: '1px solid #a5aee1',
            borderRadius: '12px',
            boxShadow: '4px 4px 36px rgba(0, 0, 0, 0.05)',
            textAlign: 'left',
            fontSize: '20px',
            color: '#212121',
            fontFamily: 'Inter',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '16px',
              marginBottom: '8px'
            }}
          >
            <Box
              sx={{
                width: '24px',
                height: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fff',
                border: '1px solid #273f77',
                borderRadius: '8px',
              }}
            >
              <PlayArrowIcon sx={{ width: '24px', height: '24px' }} />
            </Box>
            <Typography
              variant="body1"
              sx={{
                position: 'relative',
                letterSpacing: '-0.01em',
                lineHeight: '150%',
                fontWeight: 500,
              }}
            >
              {video.title}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{
              ml:'42px',
              position: 'relative',
              letterSpacing: '-0.01em',
              lineHeight: '150%',
              fontWeight: 400,
              color: '#5c5c5c',
            }}
          >
            {video.description}
          </Typography>
        </Paper>
      ))}
    </Stack>
  );
};

export default ListVideoContainer;
