import { axiosApiService } from "./axios";

const StudentTutorialAPIs = {
    ModuleListGet: async function() {
        try {
            const response = await axiosApiService.get(`training/tutorials/modules/`);
            console.log('Module List:', response);
            return response.data;
        } catch (error) {
            console.error('Error Module List:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    ModuleDetailGet: async function(module_id) {
        try {
            const response = await axiosApiService.get(`training/tutorials/modules/${module_id}/`);
            console.log(`Module ${module_id} Response:`, response);
            return response.data;
        } catch (error) {
            console.error(`Error getting module ${module_id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },

    VideoListGet: async function() {
        try {
            const response = await axiosApiService.get(`/training/tutorials/videos/`);
            console.log('Video List:', response);
            return response.data;
        } catch (error) {
            console.error('Error fetching video list:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    VideoDetailGet: async function(video_id) {
        try {
            const response = await axiosApiService.get(`/training/tutorials/videos/${video_id}/`);
            console.log(`Video ${video_id} Response:`, response);
            return response.data;
        } catch (error) {
            console.error(`Error fetching video ${video_id}:`, error.response ? error.response.data : error.message);
            throw error;
        }
    },
};

export default StudentTutorialAPIs;