import Sidebar from "components/sidebar";
import { Box } from "@mui/material";
import Header from "components/header";
import SettingsPage from "components/set/SettingsPage";

const Settings = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Header />
                <SettingsPage />
            </Box>
        </Box>
    )
}

export default Settings;