import React from 'react';
import { Box, Grid, Typography, Switch, Button } from '@mui/material';

const PrivacyTab = () => {
  return (
    <Box sx={{ padding: 4, border: '1px solid #B5B5B5', borderRadius: '12px', margin: 'auto', mt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Discoverability</Typography>
          <Typography variant="body2" color="textSecondary">
            Allow users to find you by your Email ID or Phone Number
          </Typography>
        </Grid>
        <Grid item xs={10}>
        </Grid>
        <Grid item xs={2}>
          <Switch />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Profile Avatar visibility</Typography>
          <Typography variant="body2" color="textSecondary">
            Allow users to view your actual name and profile picture
          </Typography>
        </Grid>
        <Grid item xs={10}>
        </Grid>
        <Grid item xs={2}>
          <Switch />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Location</Typography>
          <Typography variant="body2" color="textSecondary">
            Allow app to use your location
          </Typography>
        </Grid>
        <Grid item xs={10}>
        </Grid>
        <Grid item xs={2}>
          <Switch defaultChecked />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Your booking and issues</Typography>
          <Typography variant="body2" color="textSecondary">
            Allow others to view your bookings/issues
          </Typography>
        </Grid>
        <Grid item xs={10}>
        </Grid>
        <Grid item xs={2}>
          <Switch defaultChecked />
        </Grid>

        <Grid item xs={12} sx={{ mt: 4 }}>
          <Button variant="contained" color="error" sx={{textTransform: 'none'}}>
            Delete Account
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrivacyTab;
