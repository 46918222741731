// Conditional Rendering with description

import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, IconButton, Grid, CardMedia } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoPlayer from '../videoplayer/VideoPlayer';
import { onTutorialPage } from "../../../redux/slices/tutorialSlice";
import { useDispatch } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import StudentTutorialAPIs from 'utilities/api/StudentTutorialAPIs';
import ImageBaseURL from 'utilities/api/axios';

// const baseURL = 'https://staging.misadmin.mapit.ai/';

const VideoCardView = ({ tutorialID }) => {
  const dispatch = useDispatch();
  const [course, setCourse] = useState(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentVideoURL, setCurrentVideoURL] = useState('');
  const [videoDetails, setVideoDetails] = useState({});
  const [loading, setLoading] = useState(true);

  // Example MCQ data
  const mcqData = [
    { time: 5, question: 'Who is the first Avenger?', options: ['Iron Man', 'Captain America', 'Thor'], correctOption: 'Captain America', shown: false },
    { time: 10, question: 'What is the name of Thor’s hammer?', options: ['Mjolnir', 'Stormbreaker', 'Gungnir'], correctOption: 'Mjolnir', shown: false },
    { time: 15, question: 'Who is the Scarlet Witch?', options: ['Natasha Romanoff', 'Wanda Maximoff', 'Carol Danvers'], correctOption: 'Wanda Maximoff', shown: false },
    { time: 20, question: 'Which Infinity Stone is hidden on Vormir?', options: ['Soul Stone', 'Mind Stone', 'Reality Stone'], correctOption: 'Soul Stone', shown: false }
  ];

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const courseData = await StudentTutorialAPIs.ModuleDetailGet(tutorialID);
        setCourse(courseData);

        // Fetch initial video details for the first video
        if (courseData.videos.length > 0) {
          const initialVideoDetails = await StudentTutorialAPIs.VideoDetailGet(courseData.videos[0].video);
          setVideoDetails(prev => ({ ...prev, [0]: initialVideoDetails }));
          setCurrentVideoURL(initialVideoDetails.link);
        }
      } catch (error) {
        console.error('Failed to fetch course data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [tutorialID]);

  const handleVideoSelect = async (index) => {
    setCurrentVideoIndex(index);
    if (!videoDetails[index]) {
      try {
        const videoData = await StudentTutorialAPIs.VideoDetailGet(course.videos[index].video);
        setVideoDetails(prev => ({ ...prev, [index]: videoData }));
        setCurrentVideoURL(videoData.link);
      } catch (error) {
        console.error('Failed to fetch video data:', error);
      }
    } else {
      setCurrentVideoURL(videoDetails[index].link);
    }
  };

  const handlePlayerReady = (player) => {
    console.log('Player is ready:', player);
    player.on('error', () => {
      console.error('VideoJS encountered an error:', player.error());
    });
  };

  const handleWatchTimeUpdate = (watchTime) => {
    console.log(`User has watched ${watchTime} seconds`);
  };

  if (loading || !course) {
    return (
      <Box display="flex" sx={{ padding: '5px 0rem' }}>
        <Typography variant="h4" align="left" sx={{ fontSize: "1.7rem", marginBottom: '10px' }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  // Calculate the duration of each video in minutes
  const totalMinutes = parseFloat(course.no_of_time) * 60;
  const videoDurations = Array.from({ length: course.videos.length }).map((_, index) => {
    return `${(totalMinutes / course.videos.length).toFixed(2)} min`;
  });

  return (
    <Box display="flex" sx={{ padding: '5px 0rem' }}>
      <Box flex={2} mr={2} ml={-1}>
        <Typography variant="h4" align="left" sx={{ fontSize: "1.7rem", marginBottom: '10px' }}>
          <IconButton onClick={() => {
            dispatch(onTutorialPage(false));
          }}>
            <ArrowBack />
          </IconButton>
          {course.name}
        </Typography>
        <Card>
          <CardMedia>
            <VideoPlayer
              key={currentVideoURL} // Ensure component re-renders when the URL changes
              src={currentVideoURL}
              mcqData={mcqData}
              onReady={handlePlayerReady}
              onWatchTimeUpdate={handleWatchTimeUpdate}
            />
          </CardMedia>
          <CardContent>
            <Typography variant="h5" component="div">
              {course.name} - {videoDetails[currentVideoIndex]?.title || `Video ${currentVideoIndex + 1}`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {videoDetails[currentVideoIndex]?.description}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box width="300px" maxHeight="78vh" overflow="auto">
        <Grid container spacing={2} direction="column">
          {course.videos.map((_, index) => (
            <Grid item key={index}>
              <Card
                onClick={() => handleVideoSelect(index)}
                style={{
                  cursor: 'pointer',
                  border: index === currentVideoIndex ? '2px solid #1976d2' : '1px solid rgba(0, 0, 0, 0.12)',
                  boxShadow: index === currentVideoIndex ? '0 0 10px rgba(25, 118, 210, 0.5)' : 'none',
                  background: index === currentVideoIndex ? '#cbd4fe' : 'none',
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={`${ImageBaseURL.defaults.baseURL}${course.image}`} // You can replace this with an actual thumbnail URL
                  title={`${course.name} video ${index + 1}`}
                />
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <Typography variant="body1">
                        {videoDetails[index]?.title || `Video ${index + 1}`}
                      </Typography>
                      {index === currentVideoIndex && (
                        <Typography variant="body2" color="textSecondary">
                          {videoDetails[index]?.description}
                        </Typography>
                      )}
                    </Box>
                    <IconButton>
                      <PlayCircleOutlineIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default VideoCardView;
