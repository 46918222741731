import React, { useState } from 'react';
import { Paper, TableContainer } from '@mui/material';
import TableListHeader from './TableListHeader';
import TableListItem from './TableListItem';
import CustomPagination from '../../Pagination';
import { useSelector } from 'react-redux';
import FilterToolbar from './FilterToolbar';

const InventoryTableView = ({ data, setOpenDialog, searchQuery }) => {
  const { access } = useSelector((state) => state.user);

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10); // Default to 10 per page
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Calculate total number of pages
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / productsPerPage);

  // Get current products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  // Extract unique tags and categories for the filters
  const uniqueTags = [
    ...new Set(
      data.flatMap((item) => [item.tag_1, item.tag_2, item.tag_3, item.tag_4]).filter((tag) => tag)
    ),
  ];

  const uniqueCategories = [...new Set(data.map((item) => item.category))];

  // Filter products based on selected tags and categories
  const filteredData = data.filter((product) => {
    const tagMatch = selectedTags.length
      ? selectedTags.some((tag) => [product.tag_1, product.tag_2, product.tag_3, product.tag_4].includes(tag))
      : true;
    const categoryMatch = selectedCategories.length ? selectedCategories.includes(product.category) : true;

    return tagMatch && categoryMatch;
  });

  const currentProducts = filteredData.slice(indexOfFirstProduct, indexOfLastProduct);

  // Change page
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle tag change
  const handleTagChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(typeof value === 'string' ? value.split(',') : value);
  };

  // Handle category change
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <>
      <FilterToolbar
        uniqueTags={uniqueTags}
        uniqueCategories={uniqueCategories}
        selectedTags={selectedTags}
        selectedCategories={selectedCategories}
        handleTagChange={handleTagChange}
        handleCategoryChange={handleCategoryChange}
      />

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '0.5px solid rgba(181, 181, 181, 1)',
          background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
          boxShadow: 'none',
          ml: '1rem',
          width: '95%',
          borderRadius: '8px',
          mr: '1rem',
        }}
      >
        <TableListHeader />
        {currentProducts.map((product) => (
          <TableListItem
            key={product.id}
            product={product}
            setOpenDialog={setOpenDialog}
            searchQuery={searchQuery}
          />
        ))}
        <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} productsPerPage={productsPerPage} setProductsPerPage={setProductsPerPage} />
      </TableContainer>
    </>
  );
};

export default InventoryTableView;
