import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

const WorkbenchBookingHeader = () => {
    return (
        <TableHead>
            <TableRow
                sx={{
                    bgcolor: '#F7F7F7',
                    borderBottom: '1px solid #F2F2F2',
                }}
            >
                <TableCell sx={{ width: '7%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '18px' }}>
                        Workbench
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '18px' }}>
                        Booking Date
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '18px' }}>
                        Time Range
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '18px' }}>
                        Project
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '18px' }}>
                        Group Names
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '20%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '18px' }}>
                        Description
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '18px' }}>
                        Approved Status
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ width: '5%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '18px' }}>
                        Action
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default WorkbenchBookingHeader;
