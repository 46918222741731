import React, { useState } from 'react';
import { Card, Button } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import LikeButton from './LikeButton';
import { useSelector } from 'react-redux';
import EquipmentDetails from 'components/popups/EquipmentDetails';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageBaseURL from 'utilities/api/axios';

const TableListItem = ({ product, searchQuery }) => {
  const [openDialog, setOpenDialog] = useState(false); // State to manage dialog open/close

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Filter function
  const filterData = (details) => {
    const query = searchQuery.toLowerCase();
    const { name, category, description } = details;

    return (
      (name && name.toLowerCase().includes(query)) ||
      (category && category.toLowerCase().includes(query)) ||
      (description && description.toLowerCase().includes(query))
    );
  };

  const filtered = filterData(product);

  return (
    <>
      {filtered && (
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            bgcolor: 'white',
            height: 'auto',
            boxSizing: 'border-box',
            padding: '3px',
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: '50px', // Set a fixed width
              height: '50px', // Set a fixed height equal to the width
              borderRadius: '50%',
              flexShrink: 0,
              marginRight: "1vw",
              padding: '2px 4px',
              objectFit: 'cover' // Ensure image fills the circular container
            }}
            image={product.image ? `${ImageBaseURL.defaults.baseURL}${product.image}` : "./machine.png"} // Conditional image rendering
            alt={product.name}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              p: 0,
              width: '20%', // Adjusted width
              height: 'auto',
              overflow: 'hidden',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: '#898989',
                fontWeight: '400',
                fontSize: '1.4rem',
                lineHeight: '1.25rem',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {product.name}
            </Typography>
          </Box>

          <Typography
            variant="body2"
            noWrap
            sx={{
              width: '25%', // Adjusted width
              color: '#7A7A7A',
              fontWeight: '500',
              fontSize: '1.25rem',
              lineHeight: 'auto',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {product.category || 'N/A'}
          </Typography>

          <Typography
            variant="body2"
            noWrap
            sx={{
              width: '30%', // Adjusted width
              color: '#7A7A7A',
              fontWeight: '500',
              fontSize: '1.25rem',
              lineHeight: 'auto',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {product.description || 'N/A'}
          </Typography>

          <Typography
            variant="body2"
            noWrap
            sx={{
              width: '7%', // Adjusted width
              color: '#7A7A7A',
              fontWeight: '500',
              fontSize: '1.25rem',
              lineHeight: 'auto',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {product.instances || 'N/A'}
          </Typography>

          <Box
            sx={{
              width: '7%', // Adjusted width
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '0.25rem',
              padding: '0.875rem 0.75rem',
            }}
          >
            <Button onClick={handleOpenDialog}><VisibilityIcon /></Button>
            <EquipmentDetails open={openDialog} onClose={handleCloseDialog} machineId={product.id} machineDetails={product} />
          </Box>
        </Card>
      )}
    </>
  );
};

export default TableListItem;
