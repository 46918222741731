import React, { useState } from "react";
import { Box, Button, TextField, Typography, Snackbar, Alert, IconButton } from "@mui/material";
import BackgroundImage from "../../assets/Background_Image.png";
import MBFLogo from "../../utilities/dummy_assets/MBF_Logo.png"; // Import the new logo
import MapitLogo from "../../utilities/dummy_assets/mapitLoginLogo.png"; // Import the logo here
import { useNavigate } from "react-router-dom";
import googleIcon from "../../utilities/dummy_assets/logos_google-icon.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import Auth_API from "utilities/api/auth_api";
import { useDispatch } from "react-redux";
import { getUserSuccess } from "../../redux/slices/userSlice";
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.75rem",
    alignSelf: "stretch",
};

const typographyStyle = {
    color: "#5C5C5C",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.01375rem",
};

const styledTextfield = {
    display: "flex",
    gap: "0.2rem",
    alignSelf: "stretch",
    border: "1px solid #B5B5B5",
    borderRadius: "12px",
    // '& .MuiPaper-root': {
    //     border: 'none',
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none', // Remove fieldset border for outlined inputs
        },
    },
    '& .MuiInputBase-root': {
        borderRadius: '12px',
        '& input': {
            padding: '0.6rem 1rem',
        }
    }
};


const validation_schema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
});

const StudentLoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleRedirect = () => {
        window.location.href = 'https://tinkerspace.mis.mapit.ai/';
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: validation_schema,
        onSubmit: (values) => {
            Auth_API.studentLogin(values)
                .then((res) => {
                    dispatch(getUserSuccess(res.data));
                    navigate("/dashboard");
                })
                .catch((error) => {
                    setSnackbarMessage("Wrong credentials. Please try again.");
                    setOpenSnackbar(true);
                });
        },
    });

    return (
        <Box sx={{ display: "flex", position: "relative", minHeight: "100vh" }}>
            {/* Logo at top-left corner */}
            <Box
                sx={{
                    position: "absolute",
                    top: "24px",
                    left: "24px",
                }}
            >
                <img src={MBFLogo} alt="MBF Logo" style={{ width: "100px", height: "auto" }} />
            </Box>

            <Box
                sx={{
                    width: "100%",
                    minHeight: "100vh", // Ensure the box always covers the full height
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: {
                        xs: "cover",  // Full cover for small devices
                        sm: "contain", // Contain for medium devices
                        md: "cover",  // Full cover for medium to large devices
                    },
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: {
                        xs: "center",  // Centered for small devices
                        sm: "top",  // Positioned at the top for medium devices
                        md: "center 220px",  // Positioned for large devices as before
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >

                <Box
                    sx={{
                        display: "flex",
                        alignSelf: "flex-end",
                        marginTop: "2rem",
                        marginRight: "2rem",
                    }}
                >
                    <Typography
                        onClick={handleRedirect}
                        sx={{
                            textDecoration: "underline",
                            color: "#0062CC",
                            cursor: "pointer",
                        }}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') handleRedirect();
                        }}
                    >
                        Go to admin site
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "1.5rem",
                        padding: "2rem",
                        borderRadius: "1rem",
                        backgroundColor: "#FFF",
                        width: { xs: "90%", sm: "80%", md: "40%", lg: "25.2rem" },
                        boxShadow: "4px 4px 44px 0px #0000001A",
                        margin: "auto",
                        marginTop: { xs: "5rem", md: "8rem" },
                    }}
                >

                    {/* Login Form */}
                    <Box
                        component="form"
                        onSubmit={formik.handleSubmit}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1.5rem",
                            width: "100%",
                        }}
                    >
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>Email Address</Typography>
                            <TextField
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                sx={styledTextfield}
                                placeholder="Enter email address"
                            />
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>Password</Typography>
                            <TextField
                                id="password"
                                name="password"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                sx={styledTextfield}
                                placeholder="Enter password"
                            />
                        </Box>
                        <Typography
                            sx={{
                                ...typographyStyle,
                                fontSize: "20px",
                                fontweight: 500,
                                letterSpacing: "-0.01375rem",
                                alignSelf: "flex-end",
                                cursor: "pointer",
                                color: "#007BFF",
                            }}
                            onClick={() => navigate("/forgot_password")}
                        >
                            Forgot your password?
                        </Typography>

                        <Button
                            type="submit"
                            sx={{
                                display: "flex",
                                padding: "1rem",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.5rem",
                                alignSelf: "stretch",
                                borderRadius: "0.75rem",
                                backgroundColor: "#B2D7FF",
                                color: "#212121",
                                transition: "background-color 0.3s",
                                '&:hover': {
                                    backgroundColor: "#1f62c9", // Darker shade for hover
                                },
                                '&:active': {
                                    backgroundColor: "#8DAFDD", // Even darker shade for active state
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontFamily: "Inter",
                                    fontSize: "1.25rem",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01375rem",
                                    textTransform: "none", // To prevent all caps
                                }}
                            >
                                Login
                            </Typography>
                        </Button>


                    </Box>

                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between", // Distributes space between items
                        width: "100%",
                        alignItems: "center", // Aligns items vertically
                        padding: 2, // Optional: adds padding to the Box
                    }}>
                        <Typography
                            component="span"
                            onClick={() => navigate(-1)} // Navigate back
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "#007BFF",
                                cursor: "pointer",
                                fontSize: "18px",
                                fontWeight: 400,
                                lineHeight: "normal",
                                letterSpacing: "-0.01375rem",
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') navigate(-1);
                            }}
                        >
                            <ArrowBackIcon sx={{ marginRight: 0.5 }} />
                            Back
                        </Typography>
                        <Typography sx={{fontSize:'18px'}}>
                            Don’t have an account?
                            <Typography
                                component="span"
                                onClick={() => navigate("/register")}
                                sx={{
                                    ml:1,
                                    color: "#007BFF",
                                    cursor: "pointer",
                                    fontSize: "18px",
                                    fontWeight: 400,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01375rem",
                                }}
                                role="button"
                                tabIndex={0}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') navigate("/register");
                                }}
                            >
                                Register here
                            </Typography>
                        </Typography>

                    </Box>
                </Box>

                {/* Footer Logo */}
                <Box sx={{ mb: 1 }}>
                    <Typography
                        sx={{
                            color: "white",
                            fontFamily: 'roboto',
                            fontSize: '24px',
                        }}
                    >
                        Powered by
                        <img src={MapitLogo} alt="Mapit Logo" style={{ marginBottom: "-0.5rem", width: "100px", height: "auto" }} />
                    </Typography>
                </Box>
            </Box>

            {/* Snackbar Notification */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default StudentLoginPage;
