import React, { useState } from 'react';
import { TableRow, TableCell, Button, Tooltip, Typography } from '@mui/material';
import EditWorkbenchBooking from 'components/ExploreTL/workbench/EditWorkbenchBooking';



const WorkbenchBookingListItem = ({ product, onDelete, onUpdate }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleDelete = () => {
        onDelete(product.id);
    };

    const handleEdit = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Determine if the edit button should be disabled
    const isEditable = product.approved_status === '0';

    // Map approved_status to display text
    const approvalStatusMap = {
        "0": "Pending",
        "1": "Approved",
        "2": "Rejected",
    };

    // Determine color based on approval status
    const approvalColorMap = {
        "0": 'orange',
        "1": 'green',
        "2": 'red',
    };

    return (
        <>
            <TableRow
                sx={{
                    borderBottom: '1px solid #F2F2F2',
                    bgcolor: 'white',
                }}
            >
                <TableCell sx={{ width: '7%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <Typography variant="body2" sx={{ color: '#7A7A7A', fontWeight: '500', fontSize: '18px' }}>
                        {product.workbench}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <Typography variant="body2" sx={{ color: '#7A7A7A', fontWeight: '500', fontSize: '18px' }}>
                        {product.booking_date}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <Typography variant="body2" sx={{ color: '#7A7A7A', fontWeight: '500', fontSize: '18px' }}>
                        {`${product.start_time.slice(0, 5)} - ${product.end_time.slice(0, 5)}`}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <Typography variant="body2" sx={{ color: '#7A7A7A', fontWeight: '500', fontSize: '18px' }}>
                        {product.project ? product.project_name : 'N/A'}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <Typography variant="body2" sx={{ color: '#7A7A7A', fontWeight: '500', fontSize: '18px' }}>
                        {product.group_names.join(', ')}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: '20%', textAlign: 'center', padding: '0.875rem 0.75rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <Typography variant="body2" sx={{ color: '#7A7A7A', fontWeight: '500', fontSize: '18px' }}>
                        {product.description}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem', color: approvalColorMap[product.approved_status] }}>
                    <Typography variant="body2" sx={{ fontWeight: '500', fontSize: '18px' }}>
                        {approvalStatusMap[product.approved_status]}
                    </Typography>
                </TableCell>
                <TableCell sx={{ width: '5%', textAlign: 'center', padding: '0.875rem 0.75rem' }}>
                    <Tooltip title={isEditable ? "Edit this booking" : "Cannot edit approved or rejected bookings"}>
                        <span> {/* Wrapper to handle disabled tooltip */}
                            <Button
                                variant="text"
                                onClick={handleEdit}
                                disabled={!isEditable}
                                sx={{
                                    backgroundColor: isEditable ? "#E79A2B" : "#BDBDBD",
                                    color: "#fff",
                                    padding: "8px 16px",
                                    borderRadius: "8px",
                                    cursor: isEditable ? "pointer" : "not-allowed",
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    marginRight: '5px',
                                }}
                            >
                                Edit
                            </Button>
                        </span>
                    </Tooltip>
                </TableCell>
            </TableRow>

            {/* Render the EditWorkbenchBooking dialog */}
            {openDialog && (
                <EditWorkbenchBooking
                    open={openDialog}
                    onClose={handleCloseDialog}
                    bookingData={product}  // Pass current booking data
                    onUpdate={onUpdate}    // Trigger an update after editing
                />
            )}
        </>
    );
};

export default WorkbenchBookingListItem;
