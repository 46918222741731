import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import StudentUserDetailAPIs from 'utilities/api/StudentUserDetailAPIs';
import { updateUser } from '../../redux/slices/userSlice';
import ImageBaseURL from 'utilities/api/axios';

function ProfileImage() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [editMode, setEditMode] = React.useState(false);
  const [editedUser, setEditedUser] = React.useState(user);
  const [imageFile, setImageFile] = React.useState(null);

  const handleEdit = () => {
    setEditedUser({
      ...user,
      profile_pic: getProfilePicUrl(user.profile_pic), // Ensure profile_pic is correctly set
    });
    setEditMode(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Check for spaces in the username field
    if (name === 'username' && /\s/.test(value)) {
      alert("Usernames cannot contain spaces.");
      return; // Stop further execution if a space is detected
    }
  
    setEditedUser({
      ...editedUser,
      [name]: value,
    });
  };  

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);
      setEditedUser({
        ...editedUser,
        profile_pic: URL.createObjectURL(file),
      });
    }
  };

  const handleSave = async () => {
    // Check if the username contains spaces
    if (/\s/.test(editedUser.username)) {
      alert("Usernames cannot contain spaces.");
      return; // Prevent saving if validation fails
    }
  
    try {
      const formData = new FormData();
      formData.append('username', editedUser.username);
      if (imageFile) {
        formData.append('profile_pic', imageFile);
      }
  
      const updatedUser = await StudentUserDetailAPIs.StudentDetailUpdate(user.id, formData);
      console.log('Updated user:', updatedUser);
      dispatch(updateUser(updatedUser));
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Failed to save changes. Please try again.');
    }
  };  

  const getProfilePicUrl = (profilePicPath) => {
    return profilePicPath ? `${ImageBaseURL.defaults.baseURL}${profilePicPath}` : '';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '1rem',
        width: '95%', // Make the component stretch the entire width of the page
        border: '1px solid #1976d2', // Add a border around the complete component
        borderRadius: '10px', // Optional: Add border radius for a more polished look
        padding: '25px', // Optional: Add some padding inside the component
        position: 'relative', // Ensure the box is positioned relatively
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Avatar
          alt="Profile"
          src={editMode && editedUser.profile_pic ? editedUser.profile_pic : getProfilePicUrl(user.profile_pic)}
          sx={{ width: 200, height: 200, border: '2px solid rgb(231,164,67)' }}
        />
        {editMode && (
          <>
            <label htmlFor="profile-image-upload">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="profile-image-upload"
              />
              <Button
                variant="contained"
                component="span"
                sx={{ marginLeft: '1rem' }}
              >
                Upload New Image
              </Button>
            </label>
          </>
        )}
        <Box sx={{ marginLeft: '3rem' }}>
          {editMode ? (
            <TextField
              label="Username"
              name="username"
              value={editedUser.username}
              onChange={handleChange}
              fullWidth
            />
          ) : (
            <Typography
              variant="h4"
              sx={{ color: 'rgb(39,63,119)', fontWeight: '500', fontSize: '2.5rem' }}
            >
              {user.username}
            </Typography>
          )}
          <Typography variant="body2">{user.staff ? 'Staff' : 'Student'}</Typography>
          <Typography variant="body2">{user.position}</Typography>
        </Box>
      </Box>
      {editMode ? (
        <Box
          sx={{
            position: 'absolute',
            top: '12px',
            right: '12px', // Adjust these values to position the icon as per your preference
            cursor: 'pointer',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 10px',
            }}
          >
            Save
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: '12px',
            right: '12px', // Adjust these values to position the icon as per your preference
            cursor: 'pointer',
          }}
          onClick={handleEdit}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 10px',
              color: 'rgb(122,122,122)',
              fontSize: '1.1rem',
              border: '1px solid rgb(122,122,122)',
              borderRadius: '20px',
            }}
          >
            Edit
            <EditIcon sx={{ fontSize: '1rem', color: 'rgb(122,122,122)', marginLeft: '5px' }} />
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default ProfileImage;
