import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import StudentRegisterWorkshop from 'utilities/api/workshop/StudentRegisterWorkshop';

const IndividualWorkshopCard = ({ workshop }) => {
  const { access, user } = useSelector((state) => state.user);
  const workshopID = workshop.id;
  const [isEnrolled, setIsEnrolled] = useState(
    workshop.attendees.some((attendee) => attendee.user === user.id)
  );

  const handleEnroll = async () => {
    try {
      await StudentRegisterWorkshop.RegisterStudentPost(workshopID, access);
      setIsEnrolled(true);
      alert('Successfully enrolled in the workshop!');
    } catch (error) {
      console.error('Error enrolling in the workshop:', error);
      alert('Failed to enroll in the workshop. Please try Logging In again.');
    }
  };

  return (
    <Box
      sx={{
        borderRadius: 2,
        backgroundColor: 'rgb(250,251,253)',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        padding: 2,
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 1,
        }}
      >
        <Typography variant="h6" component="div">
          {workshop.title}
        </Typography>
        <Box textAlign="right">
          {workshop.from_date && workshop.to_date && (
            <Typography
              variant="body2"
              sx={{
                display: 'inline-block',
                backgroundColor: '#EBF5FF',
                color: '#0072F5',
                padding: '2px 8px',
                borderRadius: '50px',
                marginBottom: '4px',
              }}
            >
              {`${workshop.from_date} to ${workshop.to_date}`}
            </Typography>
          )}
          <Box>
          {workshop.from_time && workshop.to_time && (
            <Typography
              variant="body2"
              sx={{
                display: 'inline-block',
                backgroundColor: '#EBF5FF',
                color: '#0072F5',
                padding: '2px 8px',
                borderRadius: '50px',
              }}
            >
              {`${workshop.from_time} to ${workshop.to_time}`}
            </Typography>
          )}
          </Box>
        </Box>
      </Box>
      <Typography variant="body2" color="text.secondary" mb={2}>
        {workshop.description}
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          sx={{ backgroundColor: '#E79A2B' }}
          onClick={handleEnroll}
          disabled={isEnrolled}
        >
          {isEnrolled ? 'Enrolled' : 'Enroll Now'}
        </Button>
      </Box>
    </Box>
  );
};

export default IndividualWorkshopCard;



// import React, { useState } from 'react';
// import { Box, Typography, Button } from '@mui/material';
// import { useSelector } from 'react-redux';
// import StudentRegisterWorkshop from 'utilities/api/workshop/StudentRegisterWorkshop';

// const IndividualWorkshopCard = ({ workshop }) => {
//   const { access, user } = useSelector((state) => state.user);
//   const workshopID = workshop.id;
//   const [isEnrolled, setIsEnrolled] = useState(
//     workshop.attendees.some((attendee) => attendee.user === user.id)
//   );

//   const handleEnroll = async () => {
//     try {
//       await StudentRegisterWorkshop.RegisterStudentPost(workshopID, access);
//       setIsEnrolled(true);
//       alert('Successfully enrolled in the workshop!');
//     } catch (error) {
//       console.error('Error enrolling in the workshop:', error);
//       alert('Failed to enroll in the workshop. Please try Logging In again.');
//     }
//   };

//   return (
//     <Box sx={{
//       borderRadius: 2,
//       backgroundColor: 'rgb(250,251,253)',
//       boxShadow: 'none',
//       display: 'flex',
//       flexDirection: 'column',
//       height: 'auto',
//       border: '1px solid rgba(0, 0, 0, 0.12)',
//       padding: 2,
//       marginBottom: 2,
//     }}>
//       <Box sx={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         marginBottom: 1,
//       }}>
//         <Typography variant="h6" component="div">
//           {workshop.title}
//         </Typography>
//         <Box textAlign="right">
//           {workshop.from_date && workshop.to_date && (
//             <Typography variant="body2" color="text.secondary">
//               {`${workshop.from_date} to ${workshop.to_date}`}
//             </Typography>
//           )}
//           {workshop.from_time && workshop.to_time && (
//             <Typography variant="body2" color="text.secondary">
//               {`${workshop.from_time} to ${workshop.to_time}`}
//             </Typography>
//           )}
//         </Box>
//       </Box>
//       <Typography variant="body2" color="text.secondary" mb={2}>
//         {workshop.description}
//       </Typography>
//       <Box display="flex" justifyContent="flex-end">
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleEnroll}
//           disabled={isEnrolled}
//         >
//           {isEnrolled ? 'Enrolled' : 'Enroll Now'}
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default IndividualWorkshopCard;



// import React from 'react';
// import { Box, Typography, Button } from '@mui/material';
// import { useSelector } from 'react-redux';
// import StudentRegisterWorkshop from 'utilities/api/workshop/StudentRegisterWorkshop';

// const cardStyles = {
//   borderRadius: 8,
//   background:'rgb(250,251,253)',
//   boxShadow: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   height: 'auto',
//   border: '1px solid rgba(0, 0, 0, 0.12)',
//   padding: '16px',
//   marginBottom: '16px',
// };

// const headerStyles = {
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   marginBottom: '8px',
// };

// const IndividualWorkshopCard = ({ workshop }) => {
//   const { access, user } = useSelector((state) => state.user);
//   console.log('IndividualWorkshopCard workshop',workshop);
//   const  workshopID= workshop.id;

//   const handleEnroll = async () => {
//     // const values = {
//     // //   id: user.id,
//     // //   is_present: false,
//     //   workshop: workshop.id,
//     // //   user: user.id,
//     // };

//     try {
//     //   await StudentRegisterWorkshop.RegisterStudentPost(values, access);
//     await StudentRegisterWorkshop.RegisterStudentPost(workshopID, access);
//       alert('Successfully enrolled in the workshop!');
//     } catch (error) {
//       console.error('Error enrolling in the workshop:', error);
//       alert('Failed to enroll in the workshop. Please try again.');
//     }
//   };

//   return (
//     <Box style={cardStyles}>
//       <Box style={headerStyles}>
//         <Typography variant="h6" component="div">
//           {workshop.title}
//         </Typography>
//         <Box textAlign="right">
//           <Typography variant="body2" color="text.secondary">
//             {`${workshop.from_date} to ${workshop.to_date}`}
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             {`${workshop.from_time} to ${workshop.to_time}`}
//           </Typography>
//         </Box>
//       </Box>
//       <Typography variant="body2" color="text.secondary" mb={2}>
//         {workshop.description}
//       </Typography>
//       <Box display="flex" justifyContent="flex-end">
//         <Button variant="contained" color="primary" onClick={handleEnroll}>
//           Enroll Now
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default IndividualWorkshopCard;



// // IndividualWorkshopCard.jsx
// import React from 'react';
// import { Box, Typography, Button } from '@mui/material';

// const cardStyles = {
//   borderRadius: 8,
//   boxShadow: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   height: 'auto',
//   border: '1px solid rgba(0, 0, 0, 0.12)',
//   padding: '16px',
//   marginBottom: '16px',
// };

// const headerStyles = {
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   marginBottom: '8px',
// };

// const IndividualWorkshopCard = ({ workshop }) => {
//   return (
//     <Box style={cardStyles}>
//       <Box style={headerStyles}>
//         <Typography variant="h6" component="div">
//           {workshop.title}
//         </Typography>
//         <Box textAlign="right">
//           <Typography variant="body2" color="text.secondary">
//             {`${workshop.from_date} to ${workshop.to_date}`}
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             {`${workshop.from_time} to ${workshop.to_time}`}
//           </Typography>
//         </Box>
//       </Box>
//       <Typography variant="body2" color="text.secondary" mb={2}>
//         {workshop.description}
//       </Typography>
//       <Box display="flex" justifyContent="flex-end">
//         <Button variant="contained" color="primary">
//           Enroll Now
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default IndividualWorkshopCard;
