import React, { useState, useEffect } from 'react';
import { Paper, TableContainer } from '@mui/material';
import TableHeaders from './TableHeaders';
import CustomPagination from '../../../components/Pagination';
import TableContent from './TableContent';

const WorkshopContent = ({ workshopData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Set items per page to 5 for demonstration

  // Calculate total number of pages
  const totalItems = workshopData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = workshopData.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        border: '0.5px solid rgba(181, 181, 181, 1)',
        background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
        boxShadow: 'none',
        maxWidth: '100%',
        borderRadius: '8px',
      }}
    >
      <TableHeaders />
      {currentItems.map((item, index) => (
        <TableContent key={index} item={item} />
      ))}
      <CustomPagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
      />
    </TableContainer>
  );
};

export default WorkshopContent;



// import React, { useState , useEffect} from 'react';
// import { Paper, TableContainer } from '@mui/material';
// import TableHeaders from './TableHeaders';
// import CustomPagination from '../../../components/Pagination';
// import TableContent from './TableContent'
// // import data from '../../../utilities/dummy_data/dummy_data_workshop.json';
// // import StudentWorkshopList from 'utilities/api/workshop/StudentWorkshopList';

// const WorkshopContent = ({workshopData}) => {

//   // const [workshopData, setWorkshopData] = useState([]);
  
//   // useEffect(() => {
//   //   // Function to fetch workshop data from API
//   //   const fetchWorkshopData = async () => {
//   //     try {
//   //       const data = await StudentWorkshopList.WorkshopListGet();
//   //       setWorkshopData(data);
//   //     } catch (error) {
//   //       console.error('Error fetching workshop data:', error);
//   //     }
//   //   };

//   //   fetchWorkshopData();
//   // }, []);



//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(5); // Set items per page to 5 for demonstration

//   // Calculate total number of pages
//   const totalItems = workshopData.length;
//   const totalPages = Math.ceil(totalItems / itemsPerPage);

//   // Get current items
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = workshopData.slice(indexOfFirstItem, indexOfLastItem);

//   // Change page
//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   return (
//     <TableContainer
//       component={Paper}
//       elevation={0}
//       sx={{
//         border: '0.5px solid rgba(181, 181, 181, 1)',
//         background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
//         boxShadow: 'none',
//         maxWidth: '100%',
//         borderRadius: '8px',
//       }}
//     >
//       <TableHeaders />
//       {currentItems.map((item, index) => (
//         <TableContent key={index} item={item}/>
//       ))}
//       <CustomPagination
//         count={totalPages}
//         page={currentPage}
//         onChange={handlePageChange}
//       />
//     </TableContainer>
//   );
// };

// export default WorkshopContent;