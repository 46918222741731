import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

const SearchBar = ({ searchQuery, handleSearchQueryChange }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
            <Typography variant="h5">Watchlist</Typography>
            <TextField
                label="Search"
                value={searchQuery}
                onChange={handleSearchQueryChange}
                sx={{ width:'25%' ,marginLeft: 'auto', marginBottom: '20px' }}
            />
        </Box>
    );
};

export default SearchBar;
