import React from 'react';
import { Drawer, List, Button, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../redux/slices/userSlice';
import logo from '../utilities/dummy_assets/mapITlogo.png';
import SchoolIcon from '@mui/icons-material/School';

const drawerWidth = 118;

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { access, user } = useSelector((state) => state.user);


  const isPathActive = (path) => {
    return window.location.pathname === path;
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
  };

  const SidebarButton = ({ path, icon, title }) => (
    <Button
      component={RouterLink}
      to={path}
      variant="contained"
      title={title}
      className={`sidebarButton ${isPathActive(path) ? 'activeSidebarButton' : ''}`}
      sx={{ marginLeft: '5px' }}
    >
      {icon}
    </Button>
  );

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          overflow: 'hidden',
          backgroundColor: '#F2F3FC',
          border: '0px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100vh',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <div>
        <List className="drawer">
          <Stack direction="column" spacing={2}>
            <div className="sidebarTop">
              <RouterLink to="/dashboard">
                <img src="./MBF.png" alt="Company Logo" />
              </RouterLink>
            </div>
            {user.TL_name === 'Org-Admin' ? (
              <>
                <Button variant="contained" title="Settings" className="sidebarButton" sx={{ marginLeft: '5px' }}>
                  <SettingsOutlinedIcon />
                </Button>
                <Button
                  onClick={handleLogout}
                  variant="contained"
                  title="Logout"
                  className="sidebarButton"
                  sx={{ marginLeft: '5px' }}
                >
                  <LogoutOutlinedIcon />
                </Button>
              </>
            ) : (
              <>
                <SidebarButton path="/dashboard" icon={<DashboardOutlinedIcon />} title="Dashboard" />
                <SidebarButton path="/explore" icon={<LanguageOutlinedIcon />} title="Explore" />
                <SidebarButton path="/projects" icon={<FolderOpenOutlinedIcon />} title="Projects" />
                <SidebarButton path="/records" icon={<StoreOutlinedIcon />} title="Records" />
                <SidebarButton path="/training" icon={<SchoolIcon />} title="Training" />
                <SidebarButton path="/settings" icon={<SettingsOutlinedIcon />} title="Settings" />
                {/* <Button variant="contained" title="Settings" className="sidebarButton" sx={{ marginLeft: '5px' }} path="/settings">
                  <SettingsOutlinedIcon />
                </Button> */}
                <Button
                  onClick={handleLogout}
                  variant="contained"
                  title="Logout"
                  className="sidebarButton"
                  sx={{ marginLeft: '5px' }}
                >
                  <LogoutOutlinedIcon />
                </Button>
              </>
            )}
          </Stack>
        </List>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          marginBottom: '10px',
        }}
      >
        <img src={logo} alt="Company Logo" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </div>
    </Drawer>
  );
};

export default Sidebar;



// import React from 'react';
// import { Drawer, List, Button, Stack } from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
// import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
// import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
// import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
// import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
// import { useDispatch } from 'react-redux';
// import { logoutUser } from '../redux/slices/userSlice';
// import logo from '../utilities/dummy_assets/mapITlogo.png';

// import SchoolIcon from '@mui/icons-material/School';


// const drawerWidth = 118;

// const Sidebar = () => {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const isPathActive = (path) => {
//     return window.location.pathname === path;
//   };

//   const handleLogout = () => {
//     dispatch(logoutUser());
//     navigate('/login');
//   };

//   const SidebarButton = ({ path, icon, title }) => (
//     <Button
//       component={RouterLink}
//       to={path}
//       variant="contained"
//       title={title}
//       className={`sidebarButton ${isPathActive(path) ? 'activeSidebarButton' : ''}`}
//       sx={{ marginLeft: '5px' }}
//     >
//       {icon}
//     </Button>
//   );

//   return (
//     <Drawer
//       sx={{
//         width: drawerWidth,
//         flexShrink: 0,
//         '& .MuiDrawer-paper': {
//           width: drawerWidth,
//           boxSizing: 'border-box',
//           overflow: 'hidden',
//           backgroundColor: '#F2F3FC',
//           border: '0px',
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'space-between',
//           height: '100vh', // Adjust height as needed
//         },
//       }}
//       variant="permanent"
//       anchor="left"
//     >
//       <div>
//         <List className="drawer">
//           <Stack direction="column" spacing={2}>
//             <div className="sidebarTop">
//               <RouterLink to="/dashboard">
//                 <img src="./MBF.png" alt="Company Logo" />
//               </RouterLink>
//             </div>
//             <SidebarButton path="/dashboard" icon={<DashboardOutlinedIcon />} title="Dashboard" />
//             <SidebarButton path="/explore" icon={<LanguageOutlinedIcon />} title="Explore" />
//             <SidebarButton path="/projects" icon={<FolderOpenOutlinedIcon />} title="Projects" />
//             <SidebarButton path="/records" icon={<StoreOutlinedIcon />} title="Records" />
//             <SidebarButton path="/training" icon={<SchoolIcon />} title="Inventory" />
//             <Button variant="contained" title="Settings" className="sidebarButton" sx={{ marginLeft: '5px' }}>
//               <SettingsOutlinedIcon />
//             </Button>
//             <Button
//               onClick={handleLogout}
//               variant="contained"
//               title="Logout"
//               className="sidebarButton"
//               sx={{ marginLeft: '5px' }}
//             >
//               <LogoutOutlinedIcon />
//             </Button>
//           </Stack>
//         </List>
//       </div>

//       {/* Logo at the end of the sidebar */}
//       <div
//         style={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           padding: '10px', // Adjust as needed
//           marginBottom:'10px',
//           // backgroundColor: '#FFFFFF', // Optional background color
//           // borderTop: '1px solid #ccc', // Example border
//         }}
//       >
//         <img src={logo} alt="Company Logo" style={{ maxWidth: '100%', maxHeight: '100%' }} />
//       </div>
//     </Drawer>
//   );
// };

// export default Sidebar;






// import React from 'react';
// import { Drawer, List, Button, Stack } from '@mui/material';
// import { Link as RouterLink } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
// import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
// import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
// import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
// import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
// // import { useUser } from './../UserContext'; // Adjust the import path as necessary

// const drawerWidth = 118;

// const Sidebar = () => {

//   // const { logout } = useUser();

//   const navigate = useNavigate();

//   const isPathActive = (path) => {
//     return window.location.pathname === path;
//   };

//   const handleLogout = () => {
//     // logout(); // Call the logout function from the user context
//     // Call the logout function from the user context or any other relevant logic
//     // Optionally, redirect the user after logging out
//     navigate('/login'); // Redirect the user to the login page
//   };

//   const SidebarButton = ({ path, icon, title }) => (
//     <Button
//       component={RouterLink}
//       to={path}
//       variant="contained"
//       title={title}
//       className={`sidebarButton ${isPathActive(path) ? 'activeSidebarButton' : ''}`}
//       sx={{ marginLeft: '5px' }}
//     >
//       {icon}
//     </Button>
//   );

//   return (
//     <Drawer
//       sx={{
//         width: drawerWidth,
//         flexShrink: 0,
//         '& .MuiDrawer-paper': {
//           width: drawerWidth,
//           boxSizing: 'border-box',
//           overflow: 'hidden',
//           backgroundColor: '#F2F3FC',
//           border: '0px',
//         },
//       }}
//       variant="permanent"
//       anchor="left"
//     >
//       <div className="sidebarTop">
//         <RouterLink to="/dashboard">
//           <img src="./MBF.png" alt="Company Logo" />
//         </RouterLink>
//       </div>

//       <List className="drawer">
//         <Stack direction="column" spacing={2}>
//           <SidebarButton path="/dashboard" icon={<DashboardOutlinedIcon />} title="Dashboard" />
//           <SidebarButton path="/explore" icon={<LanguageOutlinedIcon />} title="Explore" />
//           <SidebarButton path="/projects" icon={<FolderOpenOutlinedIcon />} title="Projects" />
//           <SidebarButton path="/inventory" icon={<StoreOutlinedIcon />} title="Inventory" />
//           <Button variant="contained" title="Settings" className="sidebarButton" sx={{ marginLeft: '5px' }}>
//             <SettingsOutlinedIcon />
//           </Button>
//           <Button
//             onClick={handleLogout}
//             variant="contained"
//             title="Logout"
//             className="sidebarButton"
//             sx={{ marginLeft: '5px' }}
//           >
//             <LogoutOutlinedIcon />
//           </Button>
//         </Stack>
//       </List>
//     </Drawer>
//   );
// };

// export default Sidebar;



// import React from 'react';
// import { Link, Stack, Drawer, Button, List } from '@mui/material';
// import { useLocation } from 'react-router-dom';
// import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
// import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
// import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
// import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

// const drawerWidth = 118; // You can set the width of the sidebar

// const Sidebar = () => {
//   const location = useLocation();

//   const isActive = (path) => {
//     return location.pathname === path;
//   };

//   return (
//     <Drawer
//       sx={{
//         width: drawerWidth,
//         flexShrink: 0,
//         '& .MuiDrawer-paper': {
//           width: drawerWidth,
//           boxSizing: 'border-box',
//           overflow: "hidden",
//           backgroundColor: "#F2F3FC",
//           border: "0px"
//         },
//       }}
//       variant="permanent"
//       anchor="left"
//     >
//       <div className='sidebarTop'><Link href="/dashboard"><img src="./MBF.png" alt="logo"></img></Link></div>

//       <List className='drawer'>
//         <Stack direction="column" spacing={2}>
//           <Link href="/dashboard">
//             <Button variant="contained" title='dashboard' className={`sidebarButton ${isActive('/dashboard') ? 'activeSidebarButton' : ''}`} marginLeft="5px">
//               <DashboardOutlinedIcon />
//             </Button>
//           </Link>
//           <Link href="/explore">
//             <Button variant="contained" title='explore' className={`sidebarButton ${isActive('/explore') ? 'activeSidebarButton' : ''}`} marginLeft="5px">
//               <LanguageOutlinedIcon />
//             </Button>
//           </Link>
//           <Link href="/projects">
//           <Button variant="contained" title='projects' className={`sidebarButton ${isActive('/projects') ? 'activeSidebarButton' : ''}`} marginLeft="5px">
//             <FolderOpenOutlinedIcon />
//         </Button>
//         </Link>
//         <Link href="/inventory">

//         <Button variant="contained" title='inventory' className={`sidebarButton ${isActive('/inventory') ? 'activeSidebarButton' : ''}`} marginLeft="5px">

//             <StoreOutlinedIcon />
//         </Button>
//         </Link>
//         <Button variant="contained" title='settings' className='sidebarButton' marginLeft="5px">
//             <SettingsOutlinedIcon />
//         </Button>
//         <Button variant="contained" title='logout' className='sidebarButton' marginLeft="5px">
//             <LogoutOutlinedIcon />
//         </Button>
        
//         </Stack>
//       </List>
//     </Drawer>
//   );
// };

// export default Sidebar;