import React from 'react';
import { Card, CardContent, Typography, Box, Link } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import IndividualWorkshopCard from './IndividualWorkshopCard';
import WorkshopContent from './WorkshopContent';
import { toggleDetails, setWorkshopData, setWorkshopCategory } from '../../../redux/slices/workshopSlice';

const cardContainerStyles = {
  borderRadius: 8,
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  padding: '16px',
  marginBottom: '16px',
  overflowY: 'auto',
  maxHeight: '50vh', // Adjust the height as needed
};

const contentStyles = {
  flex: '1 0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
};

const WorkshopCards = ({ workshopData }) => {
  const dispatch = useDispatch();
  const { access, user } = useSelector((state) => state.user);

  const isUserEligible = (workshop) => {
    return (
      user.current_year &&
      workshop.eligible_years.includes(user.current_year) &&
      user.department &&
      workshop.eligible_branches.includes(user.department)
    );
  };

  const today = new Date();

  const upcomingWorkshops = workshopData.filter((workshop) => {
    const workshopStartDate = new Date(`${workshop.from_date}T${workshop.from_time}`);
    return workshopStartDate > today && isUserEligible(workshop);
  });

  const ongoingWorkshops = workshopData.filter((workshop) => {
    const workshopStartDate = new Date(`${workshop.from_date}T${workshop.from_time}`);
    const workshopEndDate = new Date(`${workshop.to_date}T${workshop.to_time}`);
    return workshopStartDate <= today && workshopEndDate >= today && isUserEligible(workshop);
  });

  const completedWorkshops = workshopData.filter((workshop) => {
    const workshopEndDate = new Date(`${workshop.to_date}T${workshop.to_time}`);
    if (workshopEndDate < today) {
      const attendee = workshop.attendees.find((att) => att.user === user.id);
      return attendee && attendee.is_present;
    }
    return false;
  });

  const handleViewAll = (workshops, workshopCategory) => {
    dispatch(toggleDetails());
    dispatch(setWorkshopData(workshops));
    dispatch(setWorkshopCategory({ payload: workshopCategory }));
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" width="100%" mb={2}>
        <Box flex="1 1 60%" mr={2}>
          <Card style={{ ...cardContainerStyles, maxHeight: '50vh' }}>
            <CardContent style={contentStyles}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography gutterBottom variant="h5" component="div">
                  Upcoming Workshops
                </Typography>
                <Link href="#" variant="body2" onClick={() => handleViewAll(upcomingWorkshops, 'Upcoming')}>
                  View All
                </Link>
              </Box>
              {upcomingWorkshops.slice(0, 2).map((workshop) => (
                <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
              ))}
              {upcomingWorkshops.length > 2 && (
                <Box mt={2}>
                  {upcomingWorkshops.slice(2).map((workshop) => (
                    <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
                  ))}
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
        <Box flex="1 1 40%">
          <Card style={{ ...cardContainerStyles, maxHeight: '50vh' }}>
            <CardContent style={contentStyles}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography gutterBottom variant="h5" component="div">
                  Ongoing Workshops
                </Typography>
                <Link href="#" variant="body2" onClick={() => handleViewAll(ongoingWorkshops, 'Ongoing')}>
                  View All
                </Link>
              </Box>
              {ongoingWorkshops.slice(0, 2).map((workshop) => (
                <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
              ))}
              {ongoingWorkshops.length > 2 && (
                <Box mt={2}>
                  {ongoingWorkshops.slice(2).map((workshop) => (
                    <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
                  ))}
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box width="100%">
        <Card style={{ ...cardContainerStyles, maxHeight: '50vh' }}>
          <CardContent style={contentStyles}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography gutterBottom variant="h5" component="div">
                Completed Workshops
              </Typography>
            </Box>
            <WorkshopContent workshopData={completedWorkshops} />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default WorkshopCards;




// // WorkshopCards.jsx
// import React from 'react';
// import { Card, CardContent, Typography, Box } from '@mui/material';
// import { useSelector } from 'react-redux';
// import IndividualWorkshopCard from './IndividualWorkshopCard';
// import WorkshopContent from './WorkshopContent';

// const cardContainerStyles = {
//   borderRadius: 8,
//   boxShadow: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   height: '100%',
//   border: '1px solid rgba(0, 0, 0, 0.12)',
//   padding: '16px',
//   marginBottom: '16px',
//   overflowY: 'auto',
//   maxHeight: '50vh', // Adjust the height as needed
// };

// const contentStyles = {
//   flex: '1 0 auto',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'flex-start',
// };

// const WorkshopCards = ({ workshopData }) => {
//   const { access, user } = useSelector((state) => state.user);

//   const isUserEligible = (workshop) => {
//     return (
//       user.current_year &&
//       workshop.eligible_years.includes(user.current_year) &&
//       user.department &&
//       workshop.eligible_branches.includes(user.department)
//     );
//   };

//   const today = new Date();

//   const upcomingWorkshops = workshopData.filter((workshop) => {
//     const workshopStartDate = new Date(`${workshop.from_date}T${workshop.from_time}`);
//     return workshopStartDate > today && isUserEligible(workshop);
//   });

//   const ongoingWorkshops = workshopData.filter((workshop) => {
//     const workshopStartDate = new Date(`${workshop.from_date}T${workshop.from_time}`);
//     const workshopEndDate = new Date(`${workshop.to_date}T${workshop.to_time}`);
//     return workshopStartDate <= today && workshopEndDate >= today && isUserEligible(workshop);
//   });

//   const completedWorkshops = workshopData.filter((workshop) => {
//     const workshopEndDate = new Date(`${workshop.to_date}T${workshop.to_time}`);
//     if (workshopEndDate < today) {
//       const attendee = workshop.attendees.find((att) => att.user === user.id);
//       return attendee && attendee.is_present;
//     }
//     return false;
//   });

//   return (
//     <Box display="flex" flexDirection="column" width="100%">
//       <Box display="flex" width="100%" mb={2}>
//         <Box flex="1 1 60%" mr={2}>
//           <Card style={{ ...cardContainerStyles, maxHeight: '54vh' }}>
//             <CardContent style={contentStyles}>
//               <Typography gutterBottom variant="h5" component="div">
//                 Upcoming Workshops
//               </Typography>
//               {upcomingWorkshops.slice(0, 2).map((workshop) => (
//                 <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
//               ))}
//               {upcomingWorkshops.length > 2 && (
//                 <Box mt={2}>
//                   {upcomingWorkshops.slice(2).map((workshop) => (
//                     <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
//                   ))}
//                 </Box>
//               )}
//             </CardContent>
//           </Card>
//         </Box>
//         <Box flex="1 1 40%">
//           <Card style={{ ...cardContainerStyles, maxHeight: '54vh' }}>
//             <CardContent style={contentStyles}>
//               <Typography gutterBottom variant="h5" component="div">
//                 Ongoing Workshops
//               </Typography>
//               {ongoingWorkshops.slice(0, 2).map((workshop) => (
//                 <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
//               ))}
//               {ongoingWorkshops.length > 2 && (
//                 <Box mt={2}>
//                   {ongoingWorkshops.slice(2).map((workshop) => (
//                     <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
//                   ))}
//                 </Box>
//               )}
//             </CardContent>
//           </Card>
//         </Box>
//       </Box>
//       <Box width="100%">
//         <Card style={{ ...cardContainerStyles, maxHeight: '50vh' }}>
//           <CardContent style={contentStyles}>
//             <Typography gutterBottom variant="h5" component="div">
//               Completed Workshops
//             </Typography>
//             <WorkshopContent workshopData={completedWorkshops} />
//           </CardContent>
//         </Card>
//       </Box>
//     </Box>
//   );
// };

// export default WorkshopCards;




// // WorkshopCards.jsx
// import React from 'react';
// import { Card, CardContent, Typography, Box } from '@mui/material';
// import { useSelector } from 'react-redux';
// import WorkshopContent from './WorkshopContent';

// const cardStyles = {
//   borderRadius: 0,
//   boxShadow: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   height: '100%',
// };

// const contentStyles = {
//   padding: 2,
//   flex: '1 0 auto',
// };

// const WorkshopCards = ({ workshopData }) => {
//   const { access, user } = useSelector((state) => state.user);

//   console.log('user', user);

//   const isUserEligible = (workshop) => {
//     return (
//       user.current_year &&
//       workshop.eligible_years.includes(user.current_year) &&
//       user.department &&
//       workshop.eligible_branches.includes(user.department)
//     );
//   };

//   const today = new Date();

//   const upcomingWorkshops = workshopData.filter((workshop) => {
//     const workshopStartDate = new Date(`${workshop.from_date}T${workshop.from_time}`);
//     return workshopStartDate > today && isUserEligible(workshop);
//   });

//   const ongoingWorkshops = workshopData.filter((workshop) => {
//     const workshopStartDate = new Date(`${workshop.from_date}T${workshop.from_time}`);
//     const workshopEndDate = new Date(`${workshop.to_date}T${workshop.to_time}`);
//     return workshopStartDate <= today && workshopEndDate >= today && isUserEligible(workshop);
//   });

//   const completedWorkshops = workshopData.filter((workshop) => {
//     const workshopEndDate = new Date(`${workshop.to_date}T${workshop.to_time}`);
//     if (workshopEndDate < today) {
//       const attendee = workshop.attendees.find((att) => att.user === user.id);
//       return attendee && attendee.is_present;
//     }
//     return false;
//   });

//   const renderWorkshops = (workshops) => {
//     return workshops.map((workshop) => (
//       <Box key={workshop.id} mb={2}>
//         <Typography variant="h6" component="div">
//           {workshop.title}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {workshop.description}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {`Date: ${workshop.from_date} to ${workshop.to_date}`}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {`Time: ${workshop.from_time} to ${workshop.to_time}`}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {`Location: ${workshop.location}`}
//         </Typography>
//       </Box>
//     ));
//   };

//   return (
//     <Box display="flex" width="100%" flexWrap="wrap">
//       <Card style={{ ...cardStyles, flex: '1 1 60%', margin: '1%' }}>
//         <CardContent style={contentStyles}>
//           <Typography gutterBottom variant="h5" component="div">
//             Upcoming Workshops
//           </Typography>
//           {renderWorkshops(upcomingWorkshops)}
//         </CardContent>
//       </Card>

//       <Card style={{ ...cardStyles, flex: '1 1 35%', margin: '1%' }}>
//         <CardContent style={contentStyles}>
//           <Typography gutterBottom variant="h5" component="div">
//             Ongoing Workshops
//           </Typography>
//           {renderWorkshops(ongoingWorkshops)}
//         </CardContent>
//       </Card>

//       <Card style={{ ...cardStyles, flex: '1 1 100%', margin: '1%' }}>
//         <CardContent style={contentStyles}>
//           <Typography gutterBottom variant="h5" component="div">
//             Completed Workshops
//           </Typography>
//           <WorkshopContent workshopData={completedWorkshops} />
//         </CardContent>
//       </Card>
//     </Box>
//   );
// };

// export default WorkshopCards;




// import React from 'react';
// import { Card, CardContent, Typography, Box } from '@mui/material';
// import { useSelector } from 'react-redux';

// const cardStyles = {
//   borderRadius: 0,
//   boxShadow: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   height: '100%',
// };

// const contentStyles = {
//   padding: 2,
//   flex: '1 0 auto',
// };

// const WorkshopCards = ({ workshopData}) => {
//   const {access, user} = useSelector((state) => state.user);

//   console.log('user',user);

//   const isUserEligible = (workshop) => {
//     // Check if the user is eligible based on year and branch
//     return (
//       user.current_year &&
//       workshop.eligible_years.includes(user.current_year) &&
//       user.department &&
//       workshop.eligible_branches.includes(user.department)
//     );
//   };

//   const today = new Date();

//   const upcomingWorkshops = workshopData.filter(
//     workshop => new Date(workshop.from_date) > today && isUserEligible(workshop)
//   );

//   const ongoingWorkshops = workshopData.filter(
//     workshop =>
//       new Date(workshop.from_date) <= today &&
//       new Date(workshop.to_date) >= today &&
//       isUserEligible(workshop)
//   );

//   const completedWorkshops = workshopData.filter(workshop => {
//     const workshopEndDate = new Date(workshop.to_date);
//     if (workshopEndDate < today) {
//       const attendee = workshop.attendees.find(att => att.user === user.id);
//       return attendee && attendee.is_present;
//     }
//     return false;
//   });

//   const renderWorkshops = (workshops) => {
//     return workshops.map(workshop => (
//       <Box key={workshop.id} mb={2}>
//         <Typography variant="h6" component="div">
//           {workshop.title}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {workshop.description}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {`Date: ${workshop.from_date} to ${workshop.to_date}`}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {`Time: ${workshop.from_time} to ${workshop.to_time}`}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {`Location: ${workshop.location}`}
//         </Typography>
//       </Box>
//     ));
//   };

//   return (
//     <Box display="flex" width="100%" flexWrap="wrap">
//       <Card style={{ ...cardStyles, flex: '1 1 60%', margin: '1%' }}>
//         <CardContent style={contentStyles}>
//           <Typography gutterBottom variant="h5" component="div">
//             Upcoming Workshops
//           </Typography>
//           {renderWorkshops(upcomingWorkshops)}
//         </CardContent>
//       </Card>

//       <Card style={{ ...cardStyles, flex: '1 1 35%', margin: '1%' }}>
//         <CardContent style={contentStyles}>
//           <Typography gutterBottom variant="h5" component="div">
//             Ongoing Workshops
//           </Typography>
//           {renderWorkshops(ongoingWorkshops)}
//         </CardContent>
//       </Card>

//       <Card style={{ ...cardStyles, flex: '1 1 100%', margin: '1%' }}>
//         <CardContent style={contentStyles}>
//           <Typography gutterBottom variant="h5" component="div">
//             Completed Workshops
//           </Typography>
//           {renderWorkshops(completedWorkshops)}
//         </CardContent>
//       </Card>
//     </Box>
//   );
// };

// export default WorkshopCards;



// import React from 'react';
// import { Card, CardContent, Typography, Box } from '@mui/material';

// const cardStyles = {
//   borderRadius: 0,
//   boxShadow: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   height: '100%',
// };

// const contentStyles = {
//   padding: 2,
//   flex: '1 0 auto',
// };

// const WorkshopCards = ({ workshopData }) => {
//   const upcomingWorkshops = workshopData.filter(workshop => new Date(workshop.to_date) >= new Date());
//   const completedWorkshops = workshopData.filter(workshop => new Date(workshop.to_date) < new Date());

//   const renderWorkshops = (workshops) => {
//     return workshops.map(workshop => (
//       <Box key={workshop.id} mb={2}>
//         <Typography variant="h6" component="div">
//           {workshop.title}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {workshop.description}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {`Date: ${workshop.from_date} to ${workshop.to_date}`}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {`Time: ${workshop.from_time} to ${workshop.to_time}`}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {`Location: ${workshop.location}`}
//         </Typography>
//       </Box>
//     ));
//   };

//   return (
//     <Box display="flex" width="100%" flexWrap="wrap">
//       <Card style={{ ...cardStyles, flex: '1 1 60%', margin: '1%' }}>
//         <CardContent style={contentStyles}>
//           <Typography gutterBottom variant="h5" component="div">
//             Upcoming Workshops
//           </Typography>
//           {renderWorkshops(upcomingWorkshops)}
//         </CardContent>
//       </Card>

//       <Card style={{ ...cardStyles, flex: '1 1 35%', margin: '1%' }}>
//         <CardContent style={contentStyles}>
//           <Typography gutterBottom variant="h5" component="div">
//             Completed Workshops
//           </Typography>
//           {renderWorkshops(completedWorkshops)}
//         </CardContent>
//       </Card>
//     </Box>
//   );
// };

// export default WorkshopCards;
