import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import StudentPasswordAPIs from "utilities/api/StudentPasswordAPIs";
import BackgroundImage from "../../assets/Background_Image.png"; // New Background Image
import MBFLogo from "../../utilities/dummy_assets/MBF_Logo.png"; // Import the new logo
import MapitLogo from "../../utilities/dummy_assets/mapitLoginLogo.png"; // Import the logo here
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const boxStyle = {
    display: "flex",
    color: "#5C5C5C",
    fontSize: "18px",
    fontWeight: 400,
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.75rem",
    alignSelf: "stretch",
};

const typographyStyle = {
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.01375rem",
};

const styledTextfield = {
    display: "flex",
    gap: "0.2rem",
    alignSelf: "stretch",
    border: "1px solid #B5B5B5",
    borderRadius: "12px",
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
    },
    '& .MuiInputBase-root': {
        borderRadius: '12px',
        '& input': {
            padding: '0.6rem 1rem',
        }
    }
};

const validation_schema = Yup.object({
    new_password: Yup.string()
        .min(8, "Password must be at least 8 characters long") // Minimum length
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter") // At least one uppercase letter
        .matches(/[a-z]/, "Password must contain at least one lowercase letter") // At least one lowercase letter
        .matches(/\d/, "Password must contain at least one number") // At least one number
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character") // At least one special character
        .matches(/^\S*$/, "Password must not contain spaces") // No spaces
        .required("Required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required('Required'),
});

const ResetPasswordPage = () => {
    const [passwordReset, setPasswordReset] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get("token");

    const formik = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",
        },
        validationSchema: validation_schema,
        onSubmit: (values) => {
            const data = {
                token: token,
                new_password: values.new_password,
            };
            StudentPasswordAPIs.ResetPassword(data)
                .then((res) => {
                    setPasswordReset(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    return (
        <Box sx={{ display: "flex", position: "relative", minHeight: "100vh", flexDirection: "column" }}>
            {/* Logo at top-left corner */}
            <Box
                sx={{
                    position: "absolute",
                    top: "24px",
                    left: "24px",
                }}
            >
                <img src={MBFLogo} alt="MBF Logo" style={{ width: "100px", height: "auto" }} />
            </Box>

            <Box
                sx={{
                    width: "100%",
                    flex: 1,
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: {
                        xs: "cover",
                        sm: "contain",
                        md: "cover",
                    },
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: {
                        xs: "center",
                        sm: "top",
                        md: "center 220px",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {!passwordReset ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1.5rem",
                            padding: "1rem",
                            borderRadius: "1rem",
                            backgroundColor: "#FFF",
                            width: { xs: "90%", sm: "80%", md: "40%", lg: "25.2rem" },
                            boxShadow: "4px 4px 44px 0px #0000001A",
                            marginTop: { xs: "5rem", md: "8rem" },
                        }}
                    >
                        <Typography sx={{ ...typographyStyle, fontSize: "28px", fontWeight: 600, mb: -2 }}>
                            Reset Your Password
                        </Typography>
                        <Typography sx={{ color: "#5C5C5C", fontSize: "18px", textAlign: "center", fontWeight: 400 }}>
                            Please enter your new password below.
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={formik.handleSubmit}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "1.5rem",
                                width: "100%",
                            }}
                        >
                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>New Password*</Typography>
                                <TextField
                                    id="new_password"
                                    name="new_password"
                                    type="password"
                                    value={formik.values.new_password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                                    sx={styledTextfield}
                                    placeholder="Enter new password"
                                    fullWidth
                                />
                                {formik.touched.new_password && formik.errors.new_password && (
                                    <Typography color="error" variant="body2">
                                        {formik.errors.new_password}
                                    </Typography>
                                )}
                            </Box>
                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>Confirm Password*</Typography>
                                <TextField
                                    id="confirm_password"
                                    name="confirm_password"
                                    type="password"
                                    value={formik.values.confirm_password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                                    sx={styledTextfield}
                                    placeholder="Confirm new password"
                                    fullWidth
                                />
                                {formik.touched.confirm_password && formik.errors.confirm_password && (
                                    <Typography color="error" variant="body2">
                                        {formik.errors.confirm_password}
                                    </Typography>
                                )}
                            </Box>
                            <Button
                                type="submit"
                                sx={{
                                    display: "flex",
                                    padding: "0.8rem 1rem",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    alignSelf: "stretch",
                                    borderRadius: "0.75rem",
                                    backgroundColor: "#B2D7FF",
                                    color: "#212121",
                                    transition: "background-color 0.3s",
                                    '&:hover': {
                                        backgroundColor: "#1f62c9",
                                    },
                                    '&:active': {
                                        backgroundColor: "#8DAFDD",
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontFamily: "Inter",
                                        fontSize: "1.25rem",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        letterSpacing: "-0.01375rem",
                                        textTransform: "none",
                                    }}
                                >
                                    Reset Password
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1.5rem",
                            padding: "2rem",
                            borderRadius: "1rem",
                            backgroundColor: "#FFF",
                            width: { xs: "90%", sm: "80%", md: "40%", lg: "25.2rem" },
                            boxShadow: "4px 4px 44px 0px #0000001A",
                            marginTop: { xs: "5rem", md: "8rem" },
                        }}
                    >
                        <CheckCircleIcon sx={{ fontSize: 50, color: "#1e6eb2" }} />
                        <Typography sx={{ ...typographyStyle, fontSize: "28px", fontWeight: 600 }}>
                            Password Reset Successfully
                        </Typography>
                        <Typography sx={{ color: "#5C5C5C", fontSize: "16px", textAlign: "center" }}>
                            Your password has been reset successfully. You can now log in with your new password.
                        </Typography>
                        <Button
                            onClick={() => navigate("/login")}
                            sx={{
                                display: "flex",
                                padding: "1rem",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.5rem",
                                alignSelf: "stretch",
                                borderRadius: "0.75rem",
                                backgroundColor: "#FFF",
                                border: "1px solid #007BFF",
                                '&:hover': {
                                    backgroundColor: "#B2D7FF",
                                },
                                textTransform: "none",
                            }}
                        >
                            <Typography
                                sx={{
                                    color: "#007BFF",
                                    textAlign: "center",
                                    fontFamily: "Inter",
                                    fontSize: "1.25rem",
                                    fontWeight: 600,
                                    letterSpacing: "-0.01375rem",
                                }}
                            >
                                Go to Login
                            </Typography>
                        </Button>
                    </Box>
                )}
            </Box>

            {/* Footer Logo - Fixed at the bottom */}
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    padding: "1rem 0",
                }}
            >
                <Typography
                    sx={{
                        color: "white",
                        fontFamily: 'roboto',
                        fontSize: '24px',
                    }}
                >
                    Powered by
                    <img src={MapitLogo} alt="Mapit Logo" style={{ marginLeft: "0.5rem", width: "100px", height: "auto", verticalAlign: "middle" }} />
                </Typography>
            </Box>
        </Box>
    );
};

export default ResetPasswordPage;
