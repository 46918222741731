import React, { useState, useEffect } from 'react';
import Header from 'components/header';
import Noproject from 'components/content/noproject';
import Sidebar from 'components/sidebar';
import { Box } from '@mui/material';
import AddProject from 'components/popups/AddProject';
import { useUser } from './../UserContext'; // Adjust the import path as necessary
import { useSelector } from 'react-redux';
import TopBar from 'components/training/Topbar';

const Training = ({ collapsed, setCollapsed }) => {

    const userData = useSelector((state) => state.user);
    console.log(userData);

    const [open, setOpen] = useState(false);
    const handleCloseDialog = () => {
        setOpen(false);
    };

  return (
    <Box sx={{display:'flex'}}>
            <Sidebar/>
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', width:'100%'}}>
                <Header/>
                <TopBar />
            </Box>
        </Box>
  );
};

export default Training;
