import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

const TableContent = ({ item }) => {
  const buttonStyles = {
    display: 'flex',
    width: '100%',
    padding: '0px',
    margin: '0px',
    minWidth: '0px',
    color: '#273F77',
    backgroundColor: 'transparent',
    boxShadow: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  };

  const handleOpenDialog = () => {
    // Logic for opening dialog or handling button actions
  };

  const handleOverflow = (event) => {
    const element = event.target;
    element.style.maxHeight = '2rem';
    element.style.overflow = 'hidden';
    element.style.textOverflow = 'ellipsis';
  };

  return (
    <Box sx={{ overflowX: 'auto', maxHeight: 'calc(100vh - 20px)', overflowY: 'auto' }}>
      <Card sx={{ display: 'flex', alignItems: 'center', bgcolor: 'white', marginBottom: '8px', width: '100%', height: '100%' }}>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '1rem',
            padding: '0.875rem 0.75rem',
            width: '25%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            maxHeight: '2rem',
          }}
          onOverflow={handleOverflow}
        >
          {item.title}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '1rem',
            padding: '0.875rem 0.75rem',
            width: '30%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            maxHeight: '2rem',
          }}
          onOverflow={handleOverflow}
        >
          {item.description}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '1rem',
            padding: '0.875rem 0.75rem',
            width: '25%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            maxHeight: '2rem',
          }}
          onOverflow={handleOverflow}
        >
          {`${item.from_date} ${item.from_time} - ${item.to_date} ${item.to_time}`}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            color: '#898989',
            fontWeight: '400',
            fontSize: '1rem',
            padding: '0.875rem 0.75rem',
            width: '10%',
            wordWrap: 'break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
          }}
        >
          {item.attendees.length}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            overflow: 'hidden',
            width: '10%',
            justifyContent: 'center',
            fontSize: '0.5rem',
            marginInline: '0.5rem',
          }}
        >
          {/* <Button variant="contained" sx={buttonStyles} onClick={handleOpenDialog}>
            <DeleteOutlineIcon sx={{ color: 'rgb(208,36,36)' }} />
          </Button> */}
          <Button variant="contained" sx={buttonStyles} onClick={handleOpenDialog}>
            <VisibilityIcon sx={{ color: 'rgb(0,123,255)' }} />
          </Button>
          {/* <Button variant="contained" sx={buttonStyles} onClick={handleOpenDialog}>
            <EditIcon />
          </Button> */}
        </Typography>
      </Card>
    </Box>
  );
};

export default TableContent;



// import React from 'react';
// import Card from '@mui/material/Card';
// import Typography from '@mui/material/Typography';
// import { Box, Button } from '@mui/material';
// import { useSelector, useDispatch } from 'react-redux';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import EditIcon from '@mui/icons-material/Edit';
// // import { toggleEditMode } from '../../../redux/slices/editModeSlice'; // Adjust the path as per your file structure

// const TableContent = ({ item }) => {
//   // const dispatch = useDispatch();
//   // const isEdit = useSelector((state) => state.editMode.editMode); // Accessing editMode state from Redux

//   const buttonStyles = {
//     display: 'flex',
//     width: '100%',
//     padding: '0px',
//     margin: '0px',
//     minWidth: '0px',
//     color: '#273F77',
//     backgroundColor: 'transparent',
//     boxShadow: '0',
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//   };

//   const handleOpenDialog = () => {
//     // dispatch(toggleEditMode()); // Dispatching toggle edit mode action
//   };

//   const handleOverflow = (event) => {
//     const element = event.target;
//     element.style.maxHeight = '2rem';
//     element.style.overflow = 'hidden';
//     element.style.textOverflow = 'ellipsis';
//   };

//   return (
//     <Box sx={{ overflowX: 'auto', maxHeight: 'calc(100vh - 20px)', overflowY: 'auto' }}>
//       <Card sx={{ display: 'flex', alignItems: 'center', bgcolor: 'white', marginBottom: '8px', width: '100%', height: '100%' }}>
//         <Typography
//           sx={{
//             textAlign: 'center',
//             color: '#898989',
//             fontWeight: '400',
//             fontSize: '1rem',
//             padding: '0.875rem 0.75rem',
//             width: '25%',
//             wordWrap: 'break-word',
//             overflow: 'hidden',
//             whiteSpace: 'pre-wrap',
//             display: '-webkit-box',
//             WebkitBoxOrient: 'vertical',
//             WebkitLineClamp: 2,
//             maxHeight: '2rem',
//           }}
//           onOverflow={handleOverflow}
//         >
//           {item.title}
//         </Typography>
//         <Typography
//           sx={{
//             textAlign: 'center',
//             color: '#898989',
//             fontWeight: '400',
//             fontSize: '1rem',
//             padding: '0.875rem 0.75rem',
//             width: '30%',
//             wordWrap: 'break-word',
//             overflow: 'hidden',
//             whiteSpace: 'pre-wrap',
//             display: '-webkit-box',
//             WebkitBoxOrient: 'vertical',
//             WebkitLineClamp: 2,
//             maxHeight: '2rem',
//           }}
//           onOverflow={handleOverflow}
//         >
//           {item.description}
//         </Typography>
//         <Typography
//           sx={{
//             textAlign: 'center',
//             color: '#898989',
//             fontWeight: '400',
//             fontSize: '1rem',
//             padding: '0.875rem 0.75rem',
//             width: '25%',
//             wordWrap: 'break-word',
//             overflow: 'hidden',
//             whiteSpace: 'pre-wrap',
//             display: '-webkit-box',
//             WebkitBoxOrient: 'vertical',
//             WebkitLineClamp: 2,
//             maxHeight: '2rem',
//           }}
//           onOverflow={handleOverflow}
//         >
//           {item.time}
//         </Typography>
//         <Typography
//           sx={{
//             textAlign: 'center',
//             color: '#898989',
//             fontWeight: '400',
//             fontSize: '1rem',
//             padding: '0.875rem 0.75rem',
//             width: '10%',
//             wordWrap: 'break-word',
//             overflow: 'hidden',
//             whiteSpace: 'pre-wrap',
//           }}
//         >
//           {item.attendees.length }
//         </Typography>
//         {/* <Typography
//           sx={{
//             textAlign: 'center',
//             color: '#898989',
//             fontWeight: '400',
//             fontSize: '1rem',
//             padding: '0.875rem 0.75rem',
//             width: '10%',
//             wordWrap: 'break-word',
//             overflow: 'hidden',
//             whiteSpace: 'pre-wrap',
//           }}
//         >
//           <span>{item.tag}</span>
//         </Typography> */}
//         {/* <Typography
//           sx={{
//             textAlign: 'center',
//             color: '#898989',
//             fontWeight: '400',
//             fontSize: '1rem',
//             padding: '0.875rem 0.75rem',
//             width: '10%',
//             wordWrap: 'break-word',
//             overflow: 'hidden',
//             whiteSpace: 'pre-wrap',
//           }}
//         >
//           {item.attendance}
//         </Typography> */}
//         <Typography
//           sx={{
//             display: 'flex',
//             overflow: 'hidden',
//             width: '10%',
//             justifyContent: 'center',
//             fontSize: '0.5rem',
//             marginInline: '0.5rem',
//           }}
//         >
//           <Button variant="contained" sx={buttonStyles} onClick={handleOpenDialog}>
//             <DeleteOutlineIcon sx={{ color: 'rgb(208,36,36)' }} />
//           </Button>
//           <Button variant="contained" sx={buttonStyles} onClick={handleOpenDialog}>
//             <VisibilityIcon sx={{ color: 'rgb(0,123,255)' }} />
//           </Button>
//           {/* <Button variant="contained" sx={buttonStyles} onClick={handleOpenDialog}>
//             <EditIcon />
//           </Button> */}
//         </Typography>
//       </Card>
//     </Box>
//   );
// };

// export default TableContent;