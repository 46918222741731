import { axiosApiService } from "./axios";

const InventoryListAPIs = {
  FilterInventoryListGet: async function(accessToken) {
    try {
      const response = await axiosApiService.get(`/admin_inventory_list/`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`
        // }
      });
      // const filteredData = response.data.map(item => ({
      //   id: item.id,
      //   // availability: item.availability,
      //   status: item.status,
      //   // category: item.category,
      //   // location: item.location,
      //   name: item.name,
      //   // manufacturer: item.manufacturer,
      //   // description: item.description,
      //   // image: item.image,
        
      //   // MANUALLY ADDING DATE SINCE NOT ALREADY AVAIALBLE
      //   // date: "01/12",
      //   date: item.availability

      // }));
      // return filteredData;
      return response.data;
    } catch (error) {
      console.error('Error fetching Inventory list:', error);
      throw error;
    }
  },
};

export default InventoryListAPIs;