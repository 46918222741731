import { axiosApiService } from "./axios";

const MachineListAPIs = {
  FilterMachineListGet: async function(accessToken) {
    try {
      const response = await axiosApiService.get(`/admin_machine_list/`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`
        // }
      });
      // const filteredData = response.data.map(item => ({
      //   id: item.id,
      //   // availability: item.availability,
      //   status: item.status,
      //   // category: item.category,
      //   // location: item.location,
      //   name: item.name,
      //   // manufacturer: item.manufacturer,
      //   // description: item.description,
      //   // image: item.image,
        
      //   // MANUALLY ADDING DATE SINCE NOT ALREADY AVAIALBLE
      //   // date: "01/12",
      //   date: item.availability



      // }));
      // return filteredData;
      return response.data;
    } catch (error) {
      console.error('Error fetching machine list:', error);
      throw error;
    }
  },
};

// // Call the API function
// MachineListAPIs.FilterMachineListGet()
//   .then(response => {
//     console.log('Response:', response);
//   })
//   .catch(error => {
//     console.error('Error:', error);
//   });

export default MachineListAPIs;


// import { axiosApiService } from "./axios";
// const availabilityMapping = {
//   'Very Low': 0,
//   'Low': 1,
//   'Moderate': 2,
//   'High': 3,
//   'Always': 4
// };

// const MachineListAPIs = {
//   MachineListGet: async function(){
//     const response = await axiosApiService.get(`/admin_machine_list/`);
//     return response.data;
//   },
  
//   // REMOVED ADD INVENTORY FUNCTIONALITY

// }


// export default MachineListAPIs;


///////////////////////////////////////////////////////


// import { axiosApiService } from "./axios";


// const tagMapping = {
//   'Free': 0,
//   'Issue': 1,
//   'Purchase': 2,
//   'Exhaustible': 3,
//   'NonExhaustible': 4,
//   'InLabOnly': 5,
//   'HighValue': 6,

// }

// const MachineListAPIs = {


//   MachineListGet: async function({refresh_token, access_token}){
//     const response = await axiosApiService.get(`/admin_inventory_list/`, {
//         // headers:{
//         //     'Authorization': `Bearer ${access_token}`,
//         //     'Refresh-Token': refresh_token,
//         // },
//     });
//     return response.data;
//   },
// }
// export default MachineListAPIs;
