import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import StudentWorkshopList from 'utilities/api/workshop/StudentWorkshopList';
import ReportIssueAPI from 'utilities/api/ReportIssueApi';
import { useNavigate } from 'react-router-dom';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const CardItem = ({ number, title, description, buttonColor, buttonText, backgroundColor, borderColor, onClickEvent, showNoData }) => {
    return (
        <Paper 
            elevation={3} 
            style={{ 
                padding: '16px', 
                borderRadius: '12px', 
                position: 'relative', 
                marginTop: '24px', 
                marginRight: '1rem', 
                border: `1px solid ${borderColor}`,
                flex: 1,
                textAlign: 'center',
                backgroundColor: backgroundColor,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '104px',
                width: '400px'
            }}
        >
            <Button 
                onClick={onClickEvent}
                variant="contained" 
                style={{ 
                    position: 'absolute', 
                    top: '-14px', 
                    right: '24px', 
                    backgroundColor: buttonColor,
                    borderRadius: '50px',
                    padding: '4px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 3
                }}
            >
                <Typography style={{ 
                    fontSize: '16px', 
                    lineHeight: '20px', 
                    color: '#fff', 
                    textAlign: 'left' 
                }}>
                    {buttonText}
                </Typography>
            </Button>
            {showNoData ? (
                <Box display="flex" alignItems="center">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '100px', marginRight: '16px' }} />
                    <Typography variant="body1" color="textSecondary" style={{ textAlign: 'left' }}>
                        No {title.toLowerCase()} found
                    </Typography>
                </Box>
            ) : (
                <>
                    <Typography variant="h4" component="div" gutterBottom style={{ 
                        fontSize: '22px', 
                        fontWeight: '700', 
                        color: '#212121', 
                        zIndex: 0, 
                        textAlign: 'left' 
                    }}>
                        {number}
                    </Typography>
                    <Typography variant="body1" component="div" style={{ 
                        fontSize: '14px', 
                        textTransform: 'capitalize', 
                        fontWeight: '500', 
                        textAlign: 'left', 
                        color: '#212121', 
                        zIndex: 1, 
                        marginTop: '4px' 
                    }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="div" gutterBottom style={{ 
                        fontSize: '12px', 
                        textTransform: 'lowercase', 
                        fontWeight: '500', 
                        textAlign: 'left', 
                        color: '#7a7a7a', 
                        zIndex: 2, 
                        marginTop: '4px' 
                    }}>
                        {description}
                    </Typography>
                </>
            )}
        </Paper>
    );
};

const StatisticsCards = ({ user }) => {
    const [workshopCount, setWorkshopCount] = useState(0);
    const [reportCount, setReportCount] = useState(0);
    const [loading, setLoading] = useState(true); // Track loading state
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading state to true

            // Fetch workshops
            try {
                const workshops = await StudentWorkshopList.WorkshopListGet();
                const today = new Date();
                const completedWorkshops = workshops.filter(workshop => {
                    const workshopEndDate = new Date(`${workshop.to_date}T${workshop.to_time}`);
                    if (workshopEndDate < today) {
                        const attendee = workshop.attendees.find(att => att.user === user.id);
                        return attendee && attendee.is_present;
                    }
                    return false;
                });
                setWorkshopCount(completedWorkshops.length);
            } catch (error) {
                console.error("Failed to fetch workshops", error);
            }

            // Fetch reports
            try {
                const reports = await ReportIssueAPI.ReportListGet();
                const userReports = reports.filter(report => report.user === user.id);
                setReportCount(userReports.length);
            } catch (error) {
                console.error("Failed to fetch reports", error);
            } finally {
                setLoading(false); // Set loading state to false
            }
        };
    
        fetchData();
    }, [user]);

    const handleWorkshopClick = () => {
        navigate("/training", { state: { tab: 1 } });
    }

    const handleTutorialClick = () => {
        navigate("/training", { state: { tab: 0 } });
    }

    const handleReportClick = () => {
        navigate("/settings");
    }

    if (loading) {
        return <Typography align="center">Loading data...</Typography>; // Show loading state
    }

    return (
        <Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', flex: 1, width: '100%' }}>
            <CardItem 
                number={workshopCount} 
                title="Workshops Completed" 
                description={`${workshopCount > 0 ? '15% increase from last week' : 'No increase from last week'}`} 
                buttonColor="#978fed" 
                buttonText="Explore Now"
                backgroundColor="#e7e5f9"
                borderColor="#978fed"
                onClickEvent={handleWorkshopClick}
                showNoData={workshopCount === 0}
            />
            <CardItem 
                number={"?"}  // Dummy data for tutorials watched
                title="Tutorials Watched" 
                description="20% increase from last week" 
                buttonColor="#ca628d" 
                buttonText="Explore Now"
                backgroundColor="#f6e7ed"
                borderColor="#ca628d"
                onClickEvent={handleTutorialClick}
                showNoData={false}  // Assume there's always data for this card
            />
            <CardItem 
                number={reportCount} 
                title="Reports Issued" 
                description={`${reportCount > 0 ? '10% increase from last week' : 'No increase from last week'}`} 
                buttonColor="#6eba65" 
                buttonText="Explore Now"
                backgroundColor="#dfeddd"
                borderColor="#6eba65"
                onClickEvent={handleReportClick}
                showNoData={reportCount === 0}
            />
        </Box>
    );
};

export default StatisticsCards;
