import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Select,
  MenuItem,
  TableSortLabel
} from '@mui/material';

const TableHeaders = () => {
  const [category, setCategory] = useState('');

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <Table sx={{ width: '100%' }}>
      <TableHead>
        <TableRow
          sx={{
            // margin:'3px',
            display: 'flex',
            textAlign: 'center',
            bgcolor: '#F7F7F7',
            borderBottom: 'light #F2F2F2 1px',
          }}
        >
          <TableCell sx={{ width: '25%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2', textAlign: 'center' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', font: 'Roboto', fontWeight: '500', fontSize: '20px', lineHeight: '30px' }}>
              Workshop Name
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '30%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2', textAlign: 'center' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '30px' }}>
              Description
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '25%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2', textAlign: 'center' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '30px' }}>
              Date and Time Range
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '10%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2', textAlign: 'center' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '30px' }}>
              Registrant
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '10%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2', textAlign: 'center' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '30px' }}>
              Action
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

export default TableHeaders;
