import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog, DialogContent, DialogActions, Button, TextField, Typography, Box, MenuItem
} from '@mui/material';
import {
  DateRange as DateRangeIcon, AccessTime as AccessTimeIcon, Assignment as AssignmentIcon,
  EditNoteOutlined as EditNoteOutlinedIcon, PersonAddAlt as PersonAddAltIcon
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import BookMachineAPIs from 'utilities/api/StudentBookMachine';
import MachineDetailAPI from 'utilities/api/StudentMachineDetail';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import { toast } from 'react-toastify'; 

// Define time limit constants
const MIN_HOURS = 1; // Minimum booking duration in hours
const MAX_HOURS = 5; // Maximum booking duration in hours

const validationSchema = Yup.object().shape({
  reserved_by: Yup.string().required('Reserved By field is required'),
  reserved_date: Yup.string().required('Reserved date is required'),
  time_range: Yup.object().shape({
    from_time: Yup.string()
      .required('Start time is required')
      .test(
        'is-required-if-to_time',
        'Start time is required when End time is provided',
        function (value) {
          const { to_time } = this.parent;
          if (to_time && !value) {
            return this.createError({ path: 'time_range.from_time', message: 'Start time is required' });
          }
          return true;
        }
      ),
    to_time: Yup.string()
      .required('End time is required')
      .test(
        'is-required-if-from_time',
        'End time is required when Start time is provided',
        function (value) {
          const { from_time } = this.parent;
          if (from_time && !value) {
            return this.createError({ path: 'time_range.to_time', message: 'End time is required' });
          }
          return true;
        }
      )
      .test(
        'isAtLeastOneHour',
        `End time must be at least ${MIN_HOURS} hour(s) after Start time`,
        function (value) {
          const { from_time } = this.parent;
          if (from_time && value) {
            const fromTime = new Date(`1970-01-01T${from_time}Z`);
            const toTime = new Date(`1970-01-01T${value}Z`);
            const diffInHours = (toTime - fromTime) / 3600000; // milliseconds to hours
            return diffInHours >= MIN_HOURS;
          }
          return true;
        }
      )
      .test(
        'isAtMostFiveHours',
        `End time must be at most ${MAX_HOURS} hour(s) after Start time`,
        function (value) {
          const { from_time } = this.parent;
          if (from_time && value) {
            const fromTime = new Date(`1970-01-01T${from_time}Z`);
            const toTime = new Date(`1970-01-01T${value}Z`);
            const diffInHours = (toTime - fromTime) / 3600000; // milliseconds to hours
            return diffInHours <= MAX_HOURS;
          }
          return true;
        }
      ),
  }),
});

const EditMachineDialog = ({ open, onClose, product }) => {
  const { access, user } = useSelector((state) => state.user);
  const [machineDetails, setMachineDetails] = useState(null);
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (open && product.machine) {
      const fetchMachineDetails = async () => {
        try {
          const details = await MachineDetailAPI.MachineDetailGet(access, product.machine);
          setMachineDetails(details);
        } catch (error) {
          console.error('Failed to fetch machine details:', error);
        }
      };
      fetchMachineDetails();
    }
  }, [open, product.machine, access]);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const projectData = await ProjectAPIs.ProjectsGet();
      const filteredProjects = projectData.filter(project => project.student === user.id || project.teammates.includes(user.id));
      setProjects(filteredProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      reserved_by: product.reserved_by || user?.id || '',
      reserved_date: product.reserved_date || '',
      time_range: {
        from_time: product.start_time?.split('T')[1].split('+')[0] || '',
        to_time: product.end_time?.split('T')[1].split('+')[0] || '',
      },
      project: product.project || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { from_time, to_time } = values.time_range;
      const fromTime = new Date(`1970-01-01T${from_time}Z`);
      const toTime = new Date(`1970-01-01T${to_time}Z`);
      const durationInHours = (toTime - fromTime) / 3600000; // milliseconds to hours

      // Additional Validation Checks using defined constants
      if (durationInHours < MIN_HOURS) {
        toast.error(`The difference between start time and end time must be at least ${MIN_HOURS} hour(s).`);
        return;
      }

      if (durationInHours > MAX_HOURS) {
        toast.error(`The difference between start time and end time must not exceed ${MAX_HOURS} hour(s).`);
        return;
      }

      const updateData = {
        reserved_date: values.reserved_date,
        machine: product.machine,
        reserved_by: values.reserved_by,
        start_time: `${values.reserved_date} ${values.time_range.from_time}`,
        end_time: `${values.reserved_date} ${values.time_range.to_time}`,
        duration: durationInHours, // Add duration here
        project: values.project,
      };

      try {
        await BookMachineAPIs.ReservedMachineUpdate(product.id, updateData);
        toast.success('Reservation updated successfully!');
        onClose();
      } catch (error) {
        toast.error('Failed to update reservation. Please try again.');
        console.error('Error updating reservation:', error);
      }
    },
    enableReinitialize: true,
  });

  // Removed handleInputChange and number_of_hours as they're not needed

  const calculateTimeDifference = (start, end) => {
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);
    const startTime = new Date(0, 0, 0, startHour, startMinute, 0);
    const endTime = new Date(0, 0, 0, endHour, endMinute, 0);

    if (endTime < startTime) {
      endTime.setDate(endTime.getDate() + 1);
    }

    const diff = (endTime - startTime) / (1000 * 60 * 60); // difference in hours
    return diff;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const InfoBox = ({ title, content }) => (
    <Typography variant="body1" sx={{ color: 'rgb(0,48,68)', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '2' }}>
      <span style={{ fontWeight: '500' }}>{title}</span>: {content || 'N/A'}
    </Typography>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md" sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
        '& .MuiPaper-root': {
          display: 'flex',
          flexDirection: 'row',
          borderRadius: '35px',
          border: '1px solid #A6A6A6',
          boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        },
        minHeight:'450px'
      }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '24px',
            width: '33.3333%',
            gap: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgb(235,236,250)',
              height: '100%',
              border: '1px solid rgba(39,63,119,0.4)',
              borderRadius: '20px',
              padding: '24px',
            }}
          >
            <Box
              component="img"
              sx={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
                border: '1px solid rgba(39,63,119,0.7)',
                borderRadius: '20px'
              }}
              src={machineDetails?.image || 'popup.png'}
              alt="Machine"
            />

            <Typography variant="h6" sx={{ color: 'rgba(39,63,119,0.7)', marginBottom: '20px', fontFamily: 'Inter', fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>
              {machineDetails?.name}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
              <InfoBox title="Category" content={machineDetails?.category} />
              <InfoBox title="Location" content={machineDetails?.location} />
              <InfoBox title="UPC" content={machineDetails?.upc} />
              <InfoBox title="Manufacturer" content={machineDetails?.manufacturer} />
              <InfoBox title="Instances" content={machineDetails?.instances} />
              <InfoBox title="Description" content={machineDetails?.description} />
              <InfoBox title="Availability" content={machineDetails?.availability} />
            </Box>
          </Box>
        </Box>

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '24px',
            width: '66.6667%',
            gap: '16px',
            position: 'relative',
            minHeight:'450px'
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#273F77",
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: "600",
              mb: 2,
            }}
          >
            Edit Machine
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
              <PersonAddAltIcon sx={{ paddingRight: '10px' }} />
              <Typography variant="h6" sx={{ color: 'rgb(0,48,68)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}>
                {user?.username || ''}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
              <DateRangeIcon />
              <TextField
                type="date"
                name="reserved_date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: getCurrentDate() }}
                fullWidth
                sx={{ width: 'auto', cursor: 'pointer' }}
                value={formik.values.reserved_date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.reserved_date && Boolean(formik.errors.reserved_date)}
                helperText={formik.touched.reserved_date && formik.errors.reserved_date}
              />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb:2 }}>
              <AccessTimeIcon />
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <TextField
                  type="time"
                  name="time_range.from_time"
                  label="From Time"
                  value={formik.values.time_range.from_time}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.time_range?.from_time && Boolean(formik.errors.time_range?.from_time)}
                  helperText={formik.touched.time_range?.from_time && formik.errors.time_range?.from_time}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '160px' }}
                />
                <Typography>to</Typography>
                <TextField
                  type="time"
                  name="time_range.to_time"
                  label="To Time"
                  value={formik.values.time_range.to_time}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={(formik.touched.time_range?.to_time && Boolean(formik.errors.time_range?.to_time))}
                  helperText={formik.touched.time_range?.to_time && formik.errors.time_range?.to_time}
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '160px' }}
                />
              </Box>
            </Box>

            {/* Removed the number_of_hours field and handleInputChange as they're not needed */}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
              <AssignmentIcon />
              <TextField
                name="project"
                label="Project Title"
                variant="outlined"
                select
                fullWidth
                value={formik.values.project}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.project && Boolean(formik.errors.project)}
                helperText={formik.touched.project && formik.errors.project}
              >
                <MenuItem value="">Select Project</MenuItem>
                {projects.map(project => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.title}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <DialogActions
              sx={{
                position: 'absolute',
                bottom: '24px',
                right: '24px',
                width: 'auto',
              }}
            >
              <Button
                onClick={async () => {
                  try {
                    await BookMachineAPIs.ReservedMachineDelete(product.id);
                    toast.success('Reservation deleted successfully!');
                    onClose();
                  } catch (error) {
                    toast.error('Failed to delete reservation. Please try again.');
                    console.error('Error deleting reservation:', error);
                  }
                }}
                color="primary"
                sx={{
                  width: '80px',
                  height: '35px',
                  color: 'white',
                  backgroundColor: 'rgb(255,69,0)',
                  border: '1px solid black',
                  borderRadius: '10px',
                  '&:hover': {
                    backgroundColor: 'red',
                  },
                }}
              >
                Delete
              </Button>
              <Button
                type="submit"
                color="primary"
                sx={{
                  width: '80px',
                  height: '35px',
                  color: 'white',
                  backgroundColor: 'rgb(231,154,43)',
                  border: '1px solid black',
                  borderRadius: '10px',
                }}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
    </Dialog>
    </>
  );
};

export default EditMachineDialog;
