import React, { useState } from 'react';
import { Typography, TextField, Grid, FormControl, InputLabel, Select, MenuItem, Box, IconButton } from '@mui/material';
import IndividualWorkshopCard from './IndividualWorkshopCard'; // Adjust path as per your project structure
import { ArrowBack } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { toggleDetails } from '../../../redux/slices/workshopSlice';

const ListCardView = ({ workshopData, workshopCategory }) => {
  const dispatch = useDispatch();
  // State variables for search, filter, and sorting
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOption, setFilterOption] = useState('all'); // Example: 'all', 'upcoming', 'past'
  const [sortOption, setSortOption] = useState('asc'); // Example: 'asc', 'desc'

  // Filtered and sorted workshop data based on current state
  let filteredWorkshops = workshopData.filter(workshop => {
    // Filter logic based on searchTerm and filterOption
    if (filterOption === 'all' || (filterOption === 'upcoming' && new Date(workshop.to_date) > new Date()) || (filterOption === 'past' && new Date(workshop.to_date) < new Date())) {
      return workshop.title.toLowerCase().includes(searchTerm.toLowerCase())
        || workshop.description.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  });

  // Sorting logic based on sortOption
  if (sortOption === 'asc') {
    filteredWorkshops.sort((a, b) => new Date(a.from_date) - new Date(b.from_date));
  } else if (sortOption === 'desc') {
    filteredWorkshops.sort((a, b) => new Date(b.from_date) - new Date(a.from_date));
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterOption(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between" spacing={2} style={{ marginBottom: '1rem' }}>
        {/* Workshop category aligned to the left */}
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>
            <IconButton onClick={() => {
              dispatch(toggleDetails());
            }}>
              <ArrowBack />
            </IconButton>
            {workshopCategory.payload} Workshop
          </Typography>
        </Grid>
        {/* Search bar, filter, and sort aligned to the right */}
        <Grid item container xs={8} justifyContent="flex-end" spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Filter</InputLabel>
              <Select
                value={filterOption}
                onChange={handleFilterChange}
                label="Filter"
              >
                <MenuItem value="all">All Workshops</MenuItem>
                <MenuItem value="upcoming">Upcoming Workshops</MenuItem>
                <MenuItem value="past">Past Workshops</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Sort</InputLabel>
              <Select
                value={sortOption}
                onChange={handleSortChange}
                label="Sort"
              >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {/* Display workshop cards with max height */}
      <Box maxHeight="70vh" overflow="auto">
        {filteredWorkshops.map((workshop) => (
          <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
        ))}
      </Box>
    </div>
  );
};

export default ListCardView;





// import React from 'react';
// import { Typography, Card, CardContent, Grid } from '@mui/material';
// import IndividualWorkshopCard from './IndividualWorkshopCard'; // Adjust path as per your project structure

// const ListCardView = ({ workshopData, workshopCategory }) => {
//   return (
//     <div>
//       <Typography variant="h5" gutterBottom>
//         {workshopCategory.payload}
//       </Typography>
//       {workshopData.map((workshop) => (
//         <IndividualWorkshopCard key={workshop.id} workshop={workshop} />
//       ))}
//     </div>
//   );
// };

// export default ListCardView;
