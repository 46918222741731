import React, { useState, useEffect } from 'react';
import { TableContainer, Paper, Tabs, Tab, Box } from '@mui/material';
import BookableTab from './BookableTab';
import IssuableTab from './IssuableTab';
import PurchasableTab from './PurchasableTab';
import WorkbenchBookingsTab from './WorkbenchBookingsTab';
import { useSelector } from 'react-redux';

const RecordsTable = ({ activeTab: initialActiveTab }) => {
    const [activeTab, setActiveTab] = useState(initialActiveTab);
    const { user } = useSelector((state) => state.user);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    useEffect(() => {
        setActiveTab(initialActiveTab);  // Set the initial tab based on the prop
    }, [initialActiveTab]);

    return (
        <TableContainer component={Paper} elevation={0} sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden', paddingBottom: '4px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: '100%', padding: '1rem', paddingBottom: '0px' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    indicatorColor="none"
                    textColor="rgb(39,63,119)"
                    sx={{
                        padding: '5px',
                        height: 'auto',
                        background: 'rgb(242,243,252)',
                        border: '2px solid rgba(39,63,119,0.6)',
                        borderRadius: '12px',
                        marginBottom: '10px',
                    }}
                >
                    <Tab
                        label="Reservations" //Machines -> Not Workbench
                        sx={{
                            padding: '8px 32px',
                            height: '44px',
                            typography: 'body1',
                            fontWeight: '600',
                            border: '2px solid rgba(39,63,119,0.6)',
                            borderRadius: '8px',
                            '&.Mui-selected': {
                                background: 'rgb(39,63,119)',
                                color: 'white',
                            },
                        }}
                    />
                    <Tab
                        label="Issuable"
                        sx={{
                            marginInline: '0.5rem',
                            padding: '8px 32px',
                            height: '44px',
                            typography: 'body1',
                            fontWeight: '600',
                            border: '2px solid rgba(39,63,119,0.6)',
                            borderRadius: '8px',
                            '&.Mui-selected': {
                                background: 'rgb(39,63,119)',
                                color: 'white',
                            },
                        }}
                    />
                    <Tab
                        label="Purchasable"
                        sx={{
                            padding: '8px 32px',
                            height: '44px',
                            typography: 'body1',
                            fontWeight: '600',
                            border: '2px solid rgba(39,63,119,0.6)',
                            borderRadius: '8px',
                            '&.Mui-selected': {
                                background: 'rgb(39,63,119)',
                                color: 'white',
                            },
                        }}
                    />
                    <Tab
                        label="Workbench Bookings"
                        sx={{
                            ml:1,
                            padding: '8px 32px',
                            height: '44px',
                            typography: 'body1',
                            fontWeight: '600',
                            border: '2px solid rgba(39,63,119,0.6)',
                            borderRadius: '8px',
                            '&.Mui-selected': {
                                background: 'rgb(39,63,119)',
                                color: 'white',
                            },
                        }}
                    />
                </Tabs>
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto', paddingInline: '1rem' }}>
                {activeTab === 0 && <BookableTab user={user} />}
                {activeTab === 1 && <IssuableTab user={user} />}
                {activeTab === 2 && <PurchasableTab user={user} />}
                {activeTab === 3 && <WorkbenchBookingsTab user={user} />}
            </Box>
        </TableContainer>
    );
};

export default RecordsTable;
