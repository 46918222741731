import React, { useState, useEffect } from 'react';
import { Paper, TableContainer } from '@mui/material';
import TableListHeader from './TableListHeader';
import TableListItem from './TableListItem';
import CustomPagination from '../../Pagination';

const EquipmentTableView = ({ key, data, setOpenDialog, searchQuery }) => {
  console.log('EquipmentTableView-Data', data);
  console.log('EquipmentTableView-searchQuery', searchQuery);

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10); // Default to 10 per page

  // Filter data based on searchQuery
  const filteredData = data.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate total number of pages based on filtered data
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / productsPerPage);

  // Get current products based on pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredData.slice(indexOfFirstProduct, indexOfLastProduct);

  // Change page
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Reset to first page if searchQuery changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        border: '0.5px solid rgba(181, 181, 181, 1)',
        background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
        boxShadow: 'none',
        ml: '1rem',
        width: '95%',
        borderRadius: '8px',
        mr: '1rem',
      }}
    >
      <TableListHeader />
      {currentProducts.map((product) => (
        <TableListItem
          key={product.id}
          product={product}
          setOpenDialog={() => setOpenDialog(product.id)}
          searchQuery={searchQuery}
        />
      ))}
      <CustomPagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        productsPerPage={productsPerPage}
        setProductsPerPage={setProductsPerPage}
      />
    </TableContainer>
  );
};

export default EquipmentTableView;
