import React, { useState, useEffect } from "react";
import { Container, Typography, Button, Box, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import ListVideoContainer from "./ListVideoContainer"; // Adjust the import path as necessary
import {
  setOnDetail,
  onTutorialPage,
} from "../../../redux/slices/tutorialSlice";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { ArrowBack } from '@mui/icons-material';
import StudentTutorialAPIs from "utilities/api/StudentTutorialAPIs";

const CoursePage = ({ tutorialID }) => {
  const dispatch = useDispatch();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [videoDetails, setVideoDetails] = useState([]);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const courseData = await StudentTutorialAPIs.ModuleDetailGet(tutorialID);
        setCourse(courseData);
        
        const videoPromises = courseData.videos.map(video => 
          StudentTutorialAPIs.VideoDetailGet(video.video)
        );
        const videoDetails = await Promise.all(videoPromises);
        setVideoDetails(videoDetails);
      } catch (error) {
        console.error('Failed to fetch course data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [tutorialID]);

  const handleWatchNowClick = () => {
    dispatch(onTutorialPage(true));
  };

  const handleDetailClick = () => {
    dispatch(setOnDetail(course));
  };

  if (loading) {
    return (
      <Container sx={{ margin: "0 -24px", padding: "20px" }}>
        <Typography variant="h4" align="left" sx={{ fontSize: "2rem" }}>
          Loading...
        </Typography>
      </Container>
    );
  }

  if (!course) {
    return (
      <Container sx={{ margin: "0 -24px", padding: "20px" }}>
        <Typography variant="h4" align="left" sx={{ fontSize: "2rem" }}>
          Course not found
        </Typography>
      </Container>
    );
  }

  // Calculate total minutes
  const totalMinutes = videoDetails.length * 10; // Assuming each video is approximately 10 minutes

  return (
    <Container sx={{ margin: "0 -24px", padding: "20px" }}>
      {/* Course Name */}
      <Typography variant="h4" align="left" sx={{ fontSize: "2rem" }}>
        <IconButton onClick={handleDetailClick}>
          <ArrowBack />
        </IconButton>
        {course.name}
      </Typography>

      {/* Buttons */}
      <Box sx={{ margin: "10px 0" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlayArrowIcon />}
          sx={{
            marginRight: "10px",
            margin: '0 4px',
            textTransform: 'none',
            backgroundColor: '#E79A2B',
            color: '#FFF',
            '&:hover': {
              backgroundColor: '#f9c346',
            },
          }}
          onClick={handleWatchNowClick}
        >
          Watch now
        </Button>
        <Button
          variant="outlined"
          startIcon={<PlaylistAddIcon />}
          sx={{
            flexGrow: 1,
            margin: '0 4px',
            textTransform: 'none',
            borderColor: '#000',
            color: '#000',
            '&:hover': {
              borderColor: '#000',
            },
          }}
        >
          Add to watchlist
        </Button>
      </Box>

      {/* About the Course */}
      <Typography variant="h5" align="left" sx={{ fontSize: "1.5rem", marginBottom: '10px' }}>
        About the Course
      </Typography>
      <Typography variant="body2" align="left" sx={{ fontSize: "1.2rem", marginBottom: '10px' }}>
        {course.description}
      </Typography>

      {/* Video Courses */}
      <Typography variant="h5" align="left" sx={{ fontSize: '1.5rem', marginBottom: '10px' }}>
        Video Courses
        <span style={{ marginLeft: '5px', fontSize: '1rem', fontWeight: 'normal' }}>
          ( {videoDetails.length} videos, {(totalMinutes / 60).toFixed(2)} hours )
        </span>
      </Typography>

      {/* Video List */}
      <ListVideoContainer videos={videoDetails} />
    </Container>
  );
};

export default CoursePage;
