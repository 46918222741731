import { Box, Typography } from "@mui/material";
import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import NotificationTab from "./NotificationTab";
import PrivacyTab from "./PrivacyTab";

const SettingsPage = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ margin: 4, }}>
            <Typography sx={{ color: '#273F77', fontSize: '200%', fontWeight: '400', lineHeight: 'normal', }}>Settings</Typography>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{ marginTop: 2, marginBottom: 2 }}
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                }}
            >
                <Tab
                    icon={<NotificationsIcon
                    // sx={{ color: value === 0 ? '#BC7700' : '#33406C', }} 
                    />}
                    iconPosition="start"
                    label="Notification"
                    sx={{
                        marginRight: 2,
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        '& .MuiTab-wrapper': {
                            flexDirection: 'row',
                            alignItems: 'center',
                        },
                        fontSize: '1rem',
                        fontWeight: 'bolder',
                        textTransform: 'none',
                        color: value === 0 ? '#BC7700' : '#33406C',
                        border: value === 0 ? '1px solid #BC7700' : 'none',
                        borderRadius: '8px',
                        backgroundColor: value === 0 ? '#FFF3E0' : 'transparent',
                        padding: '0px 16px',
                    }}
                />
                <Tab
                    icon={<PrivacyTipIcon
                    //  sx={{ color: value === 1 ? '#BC7700' : '#33406C', }} 
                    />}
                    iconPosition="start"
                    label="Privacy"
                    sx={{
                        marginRight: 2,
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        '& .MuiTab-wrapper': {
                            flexDirection: 'row',
                            alignItems: 'center',
                        },
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 'bolder',
                        color: value === 1 ? '#BC7700' : '#33406C',
                        border: value === 1 ? '1px solid #BC7700' : 'none',
                        borderRadius: '8px',
                        backgroundColor: value === 1 ? '#FFF3E0' : 'transparent',
                        padding: '0px 16px',
                    }}
                />
            </Tabs>
            <Box sx={{ marginTop: 4 }}>
                {value === 0 && (
                    // <Box sx={{ padding: 4, border: '1px solid #B5B5B5', borderRadius: '8px' }}>
                        <NotificationTab />
                    // </Box>
                )}
                {value === 1 && (
                    <PrivacyTab />
                )}
            </Box>
        </Box>
    )
}

export default SettingsPage;