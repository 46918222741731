import { axiosApiService } from "../axios";

const StudentWorkshopList = {
    WorkshopListGet: async function () {
        
        const response = await axiosApiService.get(`training/workshops/`);
        console.log((response));
        return response.data
    },
    
}
export default StudentWorkshopList;