import { axiosApiService } from "../axios";

const StudentRegisterWorkshop = {
    RegisterStudentPost: async function(workshopID, access) {
        if (!access) {
            throw new Error('Access token is missing.');
        }
        try {
            const response = await axiosApiService.post(
                `/training/workshops/${workshopID}/register/`,
                {}, // pass empty data object since you're not sending any request body
                {
                    headers: {
                        Authorization: `Bearer ${access}`
                    }
                }
            );
            console.log(response);
            return response.data; // Assuming the server returns the updated booking data
        } catch (error) {
            console.error('Error enrolling for workshop:', error);
            throw error;
        }
    },
};
export default StudentRegisterWorkshop;
