import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import StudentPasswordAPIs from "utilities/api/StudentPasswordAPIs";
import BackgroundImage from "../../assets/Background_Image.png"; // New Background Image
import MBFLogo from "../../utilities/dummy_assets/MBF_Logo.png"; // Import the new logo
import MapitLogo from "../../utilities/dummy_assets/mapitLoginLogo.png"; // Import the logo here
import EmailIcon from "@mui/icons-material/Email";

const boxStyle = {
    display: "flex",
    color: "#5C5C5C",
    fontSize: "18px",
    fontWeight: 400,
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.75rem",
    alignSelf: "stretch",
};

const typographyStyle = {
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.01375rem",
};

const styledTextfield = {
    display: "flex",
    gap: "0.2rem",
    alignSelf: "stretch",
    border: "1px solid #B5B5B5",
    borderRadius: "12px",
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
    },
    '& .MuiInputBase-root': {
        borderRadius: '12px',
        '& input': {
            padding: '0.6rem 1rem',
        }
    }
};

const handleRedirect = () => {
    window.location.href = 'https://tinkerspace.mis.mapit.ai/';
};

const validation_schema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
});

const ForgotPasswordPage = () => {
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [submittedEmail, setSubmittedEmail] = useState("");
    const [timer, setTimer] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const storedExpirationTime = localStorage.getItem('resendTimerExpiration');
        if (storedExpirationTime) {
            const expirationTime = new Date(storedExpirationTime);
            const currentTime = new Date();
            const timeLeft = (expirationTime - currentTime) / 1000;
            if (timeLeft > 0) {
                setTimer(timeLeft);
                setEmailSubmitted(true);
            }
        }
    }, []);

    useEffect(() => {
        let countdown;
        if (timer > 0) {
            countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else {
            localStorage.removeItem('resendTimerExpiration');
        }
        return () => clearInterval(countdown);
    }, [timer]);

    const handleResend = () => {
        const expirationTime = new Date(new Date().getTime() + 60000);
        localStorage.setItem('resendTimerExpiration', expirationTime);
        setTimer(60);
        StudentPasswordAPIs.ForgotPassword({ email: submittedEmail })
            .then((res) => {
                setEmailSubmitted(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validation_schema,
        onSubmit: (values) => {
            setSubmittedEmail(values.email);
            const expirationTime = new Date(new Date().getTime() + 60000);
            localStorage.setItem('resendTimerExpiration', expirationTime);
            setTimer(60);
            StudentPasswordAPIs.ForgotPassword(values)
                .then((res) => {
                    setEmailSubmitted(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });

    return (
        <Box sx={{ display: "flex", position: "relative", minHeight: "100vh", flexDirection: "column" }}>
            {/* Logo at top-left corner */}
            <Box
                sx={{
                    position: "absolute",
                    top: "24px",
                    left: "24px",
                }}
            >
                <img src={MBFLogo} alt="MBF Logo" style={{ width: "100px", height: "auto" }} />
            </Box>

            <Box
                sx={{
                    width: "100%",
                    flex: 1,
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: {
                        xs: "cover",
                        sm: "contain",
                        md: "cover",
                    },
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: {
                        xs: "center",
                        sm: "top",
                        md: "center 220px",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {!emailSubmitted ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1.5rem",
                            padding: "1rem",
                            borderRadius: "1rem",
                            backgroundColor: "#FFF",
                            width: { xs: "90%", sm: "80%", md: "40%", lg: "25.2rem" },
                            boxShadow: "4px 4px 44px 0px #0000001A",
                            marginTop: { xs: "5rem", md: "8rem" },
                        }}
                    >
                        <Typography sx={{ ...typographyStyle, fontSize: "28px", fontWeight: 600, mb: -2 }}>
                            Forgot Your Password?
                        </Typography>
                        <Typography sx={{ color: "#5C5C5C", fontSize: "18px", textAlign: "center", fontWeight: 400 }}>
                            We will send you instructions to the email address below to reset your password.
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={formik.handleSubmit}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "1.5rem",
                                width: "100%",
                            }}
                        >
                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>Email Address</Typography>
                                <TextField
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    sx={styledTextfield}
                                    placeholder="Enter email address"
                                    fullWidth
                                />
                            </Box>
                            <Button
                                type="submit"
                                sx={{
                                    display: "flex",
                                    padding: "0.5rem 1rem",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    alignSelf: "stretch",
                                    borderRadius: "0.75rem",
                                    backgroundColor: "#B2D7FF",
                                    color: "#212121",
                                    mb: -1,
                                    transition: "background-color 0.3s",
                                    '&:hover': {
                                        backgroundColor: "#1f62c9",
                                    },
                                    '&:active': {
                                        backgroundColor: "#8DAFDD",
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontFamily: "Inter",
                                        fontSize: "1.25rem",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        letterSpacing: "-0.01375rem",
                                        textTransform: "none",
                                    }}
                                >
                                    Reset my password
                                </Typography>
                            </Button>
                        </Box>
                        <Typography
                            onClick={() => navigate("/login")}
                            sx={{
                                fontSize: "18px",
                                letterSpacing: "-0.01375rem",
                                color: "#007BFF",
                                cursor: "pointer",
                            }}
                        >
                            Back to login
                        </Typography>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1.5rem",
                            padding: "2rem",
                            borderRadius: "1rem",
                            backgroundColor: "#FFF",
                            width: { xs: "90%", sm: "80%", md: "40%", lg: "25.2rem" },
                            boxShadow: "4px 4px 44px 0px #0000001A",
                            marginTop: { xs: "5rem", md: "8rem" },
                        }}
                    >
                        <EmailIcon sx={{ fontSize: 50, color: "#1e6eb2" }} />
                        <Typography sx={{ ...typographyStyle, fontSize: "28px", fontWeight: 600 }}>
                            Check Your Email
                        </Typography>
                        <Typography sx={{ color: "#5C5C5C", fontSize: "16px", textAlign: "center" }}>
                            Please check the email address {submittedEmail} for instructions to reset your password.
                        </Typography>
                        <Button
                            onClick={handleResend}
                            disabled={timer > 0}
                            sx={{
                                display: "flex",
                                padding: "1rem",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.5rem",
                                alignSelf: "stretch",
                                borderRadius: "0.75rem",
                                backgroundColor: timer > 0 ? "#ccc" : "#FFF",
                                border: `1px solid ${timer > 0 ? "#ccc" : "#007BFF"}`,
                                '&:hover': {
                                    backgroundColor: timer > 0 ? "#ccc" : "#B2D7FF",
                                },
                                textTransform: "none",
                            }}
                        >
                            <Typography
                                sx={{
                                    color: timer > 0 ? "#666" : "#007BFF",
                                    textAlign: "center",
                                    fontFamily: "Inter",
                                    fontSize: "1.25rem",
                                    fontWeight: 600,
                                    letterSpacing: "-0.01375rem",
                                }}
                            >
                                {timer > 0 ? `Resend in ${Math.ceil(timer)}s` : "Resend email"}
                            </Typography>
                        </Button>
                    </Box>
                )}
            </Box>

            {/* Footer Logo - Fixed at the bottom */}
            <Box
                sx={{
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    padding: "1rem 0",
                }}
            >
                <Typography
                    sx={{
                        color: "white",
                        fontFamily: 'roboto',
                        fontSize: '24px',
                    }}
                >
                    Powered by
                    <img src={MapitLogo} alt="Mapit Logo" style={{ marginLeft: "0.5rem", width: "100px", height: "auto", verticalAlign: "middle" }} />
                </Typography>
            </Box>
        </Box>
    );
};

export default ForgotPasswordPage;
