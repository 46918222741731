import React, { useState, useEffect, useRef } from 'react';
import videojs from 'video.js';
import './video-js.css';
import MCQPopup from './MCQPopup';

const VideoPlayer = ({ src, mcqData = [], onReady, onWatchTimeUpdate }) => {
  const videoRef = useRef(null); // Reference to the video element
  const playerRef = useRef(null); // Reference to the Video.js player instance
  const watchTimeRef = useRef(0); // Ref to track watch time
  const intervalRef = useRef(null); // Ref for the interval timer
  const [showMCQ, setShowMCQ] = useState(false); // State to control MCQ popup visibility
  const [currentMCQ, setCurrentMCQ] = useState(null); // State to store the current MCQ
  const [metadataLoaded, setMetadataLoaded] = useState(false); // State to check if metadata is loaded

  const videoJsOptions = {
    controls: true,
    responsive: true,
    fluid: true,
    autoplay: false,
    sources: [
      {
        src,
        type: 'video/mp4'
      }
    ]
  };

  // Initialize Video.js player
  useEffect(() => {
    if (metadataLoaded && !playerRef.current) {
      const videoElement = document.createElement('video-js'); // Create video.js element
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement); // Append video element to the ref container

      const player = playerRef.current = videojs(videoElement, videoJsOptions, () => {
        videojs.log('player is ready');
        onReady && onReady(player); // Call onReady callback if provided
      });

      // Handle time updates to check for MCQ display timing
      player.on('timeupdate', () => {
        if (!player.paused()) {
          watchTimeRef.current = player.currentTime(); // Update watch time
          const mcq = mcqData.find(mcq => mcq.time <= watchTimeRef.current && !mcq.shown);
          if (mcq) {
            setCurrentMCQ(mcq);
            setShowMCQ(true);
            player.pause();
            mcq.shown = true; // Mark MCQ as shown
          }
        }
      });

      // Update watch time every 2 seconds when video is playing
      intervalRef.current = setInterval(() => {
        if (!player.paused()) {
          onWatchTimeUpdate && onWatchTimeUpdate(watchTimeRef.current);
        }
      }, 2000);

      player.on('ended', () => {
        console.log('User has watched 100% of the video');
      });

      // Log any errors
      player.on('error', () => {
        console.error('VideoJS encountered an error:', player.error());
      });
    }
  }, [metadataLoaded, mcqData, onReady, onWatchTimeUpdate]);

  // Fetch metadata and set the state when loaded
  useEffect(() => {
    const videoElement = document.createElement('video');
    videoElement.src = src;
    videoElement.addEventListener('loadedmetadata', () => {
      console.log('Metadata loaded:', videoElement.duration);
      setMetadataLoaded(true);
    });
    videoElement.addEventListener('error', (e) => {
      console.error('Error loading video metadata:', e);
    });

    // Cleanup
    return () => {
      videoElement.removeEventListener('loadedmetadata', () => setMetadataLoaded(true));
      videoElement.removeEventListener('error', (e) => console.error('Error loading video metadata:', e));
    };
  }, [src]);

  // Clean up player and interval on component unmount
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  // Handle correct MCQ answer
  const handleMCQCorrect = () => {
    setShowMCQ(false);
    playerRef.current.play(); // Resume video playback
  };

  // Handle retry MCQ answer
  const handleMCQRetry = () => {
    // Just hide the MCQ popup momentarily and show it again for retry
    setShowMCQ(false);
    setTimeout(() => setShowMCQ(true), 100);
  };

  return (
    <div data-vjs-player style={{ position: 'relative' }}>
      <div ref={videoRef} /> {/* Container for Video.js player */}
      {metadataLoaded && showMCQ && currentMCQ && (
        <MCQPopup
          question={currentMCQ.question}
          options={currentMCQ.options}
          correctOption={currentMCQ.correctOption}
          onCorrect={handleMCQCorrect}
          onRetry={handleMCQRetry}
        />
      )}
      {metadataLoaded && showMCQ && <div className="overlay" />} {/* Overlay to block interactions */}
    </div>
  );
};

export default VideoPlayer;



// import React, { useState, useEffect, useRef } from 'react';
// import videojs from 'video.js';
// import './video-js.css';
// import MCQPopup from './MCQPopup';

// const VideoPlayer = ({ src, mcqData = [], onReady, onWatchTimeUpdate }) => {
//   const videoRef = useRef(null); // Reference to the video element
//   const playerRef = useRef(null); // Reference to the Video.js player instance
//   const watchTimeRef = useRef(0); // Ref to track watch time
//   const intervalRef = useRef(null); // Ref for the interval timer
//   const [showMCQ, setShowMCQ] = useState(false); // State to control MCQ popup visibility
//   const [currentMCQ, setCurrentMCQ] = useState(null); // State to store the current MCQ
//   const [metadataLoaded, setMetadataLoaded] = useState(false); // State to check if metadata is loaded

//   const videoJsOptions = {
//     controls: true,
//     responsive: true,
//     fluid: true,
//     autoplay: false,
//     sources: [
//       {
//         src,
//         type: 'video/mp4'
//       }
//     ]
//   };

//   // Initialize Video.js player
//   useEffect(() => {
//     if (metadataLoaded && !playerRef.current) {
//       const videoElement = document.createElement('video-js'); // Create video.js element
//       videoElement.classList.add('vjs-big-play-centered');
//       videoRef.current.appendChild(videoElement); // Append video element to the ref container

//       const player = playerRef.current = videojs(videoElement, videoJsOptions, () => {
//         videojs.log('player is ready');
//         onReady && onReady(player); // Call onReady callback if provided
//       });

//       // Handle time updates to check for MCQ display timing
//       player.on('timeupdate', () => {
//         if (!player.paused()) {
//           watchTimeRef.current = player.currentTime(); // Update watch time
//           const mcq = mcqData.find(mcq => mcq.time <= watchTimeRef.current && !mcq.shown);
//           if (mcq) {
//             setCurrentMCQ(mcq);
//             setShowMCQ(true);
//             player.pause();
//             mcq.shown = true; // Mark MCQ as shown
//           }
//         }
//       });

//       // Update watch time every 2 seconds when video is playing
//       intervalRef.current = setInterval(() => {
//         if (!player.paused()) {
//           onWatchTimeUpdate && onWatchTimeUpdate(watchTimeRef.current);
//         }
//       }, 2000);

//       player.on('ended', () => {
//         console.log('User has watched 100% of the video');
//       });

//       // Log any errors
//       player.on('error', () => {
//         console.error('VideoJS encountered an error:', player.error());
//       });
//     }
//   }, [metadataLoaded, mcqData, onReady, onWatchTimeUpdate, src]);

//   // Fetch metadata and set the state when loaded
//   useEffect(() => {
//     const videoElement = document.createElement('video');
//     videoElement.src = src;
//     videoElement.addEventListener('loadedmetadata', () => {
//       console.log('Metadata loaded:', videoElement.duration);
//       setMetadataLoaded(true);
//     });
//     videoElement.addEventListener('error', (e) => {
//       console.error('Error loading video metadata:', e);
//     });

//     // Cleanup
//     return () => {
//       videoElement.removeEventListener('loadedmetadata', () => setMetadataLoaded(true));
//       videoElement.removeEventListener('error', (e) => console.error('Error loading video metadata:', e));
//     };
//   }, [src]);

//   // Clean up player and interval on component unmount
//   useEffect(() => {
//     const player = playerRef.current;

//     return () => {
//       if (player && !player.isDisposed()) {
//         player.dispose();
//         playerRef.current = null;
//       }
//       if (intervalRef.current) {
//         clearInterval(intervalRef.current);
//       }
//     };
//   }, []);

//   // Handle correct MCQ answer
//   const handleMCQCorrect = () => {
//     setShowMCQ(false);
//     playerRef.current.play(); // Resume video playback
//   };

//   // Handle retry MCQ answer
//   const handleMCQRetry = () => {
//     // Just hide the MCQ popup momentarily and show it again for retry
//     setShowMCQ(false);
//     setTimeout(() => setShowMCQ(true), 100);
//   };

//   return (
//     <div data-vjs-player style={{ position: 'relative' }}>
//       <div ref={videoRef} /> {/* Container for Video.js player */}
//       {metadataLoaded && showMCQ && currentMCQ && (
//         <MCQPopup
//           question={currentMCQ.question}
//           options={currentMCQ.options}
//           correctOption={currentMCQ.correctOption}
//           onCorrect={handleMCQCorrect}
//           onRetry={handleMCQRetry}
//         />
//       )}
//       {metadataLoaded && showMCQ && <div className="overlay" />} {/* Overlay to block interactions */}
//     </div>
//   );
// };

// export default VideoPlayer;
